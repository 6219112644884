import Banner from "../../components/banner";
import Button from "../../components/button";
import DETAIL_CARD from "../../components/detail_card";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ROUND_CARD_1 from "../../components/round_card_1";
import Helmet from "helmet";

export default function Uiux() {
  return (
    <div className="container-fluid">
            <Helmet>       
        <title>UI/UX</title>
            <meta name="description" content="Design is a fundamental part of our everyday lives. It shapes the way we live, work, and communicate. Design moves beyond form to explore how people interact with products, services, environments and ideas." />
            <meta name='Keywords' content=' best product design agencies in toronto, UI/UX design, frontend design, ui design, ux design'/>
        </Helmet>
      <Banner image={images.banner.uiux}>
        <h1 className="fs-1 yellow-text mb-3">UI/UX Design</h1>
        <span className="fs-4">
          We strongly believe that perfect design is key to customer loyalty. We
          combine our creativity and experience to find the best way how to
          impress your customers and make them love your product.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Features"
          paragraph="Design that suits your business:"
        />

        <div className="col-12 p-5">
          <div className="row">
            {[
              {
                title: "Branding",
                image: images.features.branding,
                text: "We provide our clients with branding and rebranding services. We express your vision of the brand – from simple idea until a recognizable brand. Our team of experts makes market research to develop the most suitable brand strategy for your product. We develop products and create brands that differ.",
              },
              {
                title: "UI/UX",
                image: images.features.ux,
                text: "We use a bunch of tools that help us to make interface catch more attention and at the same time be simple, navigating and intuitive. We are not about standard solutions, we aim to add new meaning to your digital product and make users love it.",
              },
              {
                title: "Animation/Illustration",
                image: images.features.videoEditing,
                text: "We apply different design techniques to create appealing illustrations tailor-made for your product. It helps your brand to stand out from the competitors, attract your potential audience, and convey the right message to the customers.",
              },
            ].map((el) => (
              <div className="col-12 col-lg-4 col-md-6 my-4">
                <DETAIL_CARD
                  image={el.image}
                  title={el.title}
                  text={el.text}
                  minHeight={"100%"}
                />
              </div>
            ))}
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="How does the design process look like" paragraph="" />
        <div className="row uiux-design-process-sec p-5">
          {[
            {
              title: "Empathise",
              text: "It is an important stage to gain insights about the challenge we are faced with. We involve the best experts to find out all concerns through analysis, engaging and emphasizing with people. It helps us to set aside any possible assumptions and define which goals should we achieve.",
            },
            {
              title: "Define the problem",
              text: "After our research, we collect all information and identify core problems. This stage requires you to share your vision and goal with us. It helps us to stay on the same page with you and it's easier for our designers to create right features and functions to boost your business and  solve the issue",
            },
            {
              title: "Ideate",
              text: "We create as many ideas for problem solutions as possible at the beginning of the Ideation phase. We analyze the market, learn competitors weak and strong sides, determine the target audience needs. It helps us to reduce looses and fails in future.",
            },
            {
              title: "Prototype",
              text: "As soon as a visual aspect is clear, we proceed to a technical part of design. At this stage we engage in product our development team and find out the most efficient ways of implementing it in your project. It means that we create not only design, we know the best way how to perform it into real.",
            },
            {
              title: "Testing",
              text: "Since all designs are agreed, we start the development process. From day one we do testing to make sure that the product meets criteria and requirements. Before the launch we make one more testing to ensure that the final user will get only a pleasant experience using your product.",
            },
          ].map((el, index) => (
            <div className="col-12 col-lg-4 col-md-6 my-5">
              <ROUND_CARD_1
                title={el.title}
                text={el.text}
                count={index + 1}
                minHeight={"100%"}
              />
            </div>
          ))}
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5 tech_wrapper">
          <div className="row tech_list">
            {[
              { image: images.tech.figma },
              { image: images.tech.inVision },
              { image: images.tech.adobexd },
              { image: images.tech.sketch },
              { image: images.tech.illustratoe },
              { image: images.tech.photoshop },
              { image: images.tech.uxpin },
              { image: images.tech.zeplin },
              { image: images.tech.balsamiq },
              { image: images.tech.origami },
            ].map((el) => (
              <TECH_CARD image={el.image} />
            ))}
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
