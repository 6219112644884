import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import FIRMCARD from "../../components/firmCard";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Helmet from "helmet";
export default function CONTACT() {
  return (
    <div className="container-fluid">
            <Helmet>
        <title>Cloud transformation</title>
          <meta name="description" content="Outsourcing is the process of transferring work from one country to another. This can be done to lower costs, exploit a particular skill set, or to allow a company to focus on its core competencies. Companies who want to outsource their work need." />
          <meta name='Keywords' content='software outsourcing companies in canada'/>
        </Helmet>
      <Banner image={images.banner.consulting}>
        <h1 className="fs-1 yellow-text mb-4">Consulting</h1>
        <span className="fs-3 fw-light">
          Fuel your business acceleration with impactful digital solutions.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Our IT Consulting Services"
          paragraph="Design that suits your business:"
        />
        <div className="row __consulting">
          <div className="col-12 col-md-6 row px-5">
            <div className="col-12 row d-flex align-items-center my-5">
              <div className="col-12 col-sm-3 image_container">
                <img src={images.misc.consult[2]}></img>
              </div>
              <div className="col-12 col-sm-9 mt-3">
                <h2 className="ms-3">Digital transformation consulting</h2>
              </div>
            </div>
            <h2 className="yellow-text">INVESTIGATION STAGE</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>Analyzing your goals in specific areas of business.</li>
                <li>
                  Assessing existing IT resources: networking infrastructure,
                  cloud services, digital platforms and tools, business apps,
                  security maturity.
                </li>
                <li>
                  Exploring targeted business workflows: share of manual labor,
                  time inefficiencies, error rate.
                </li>
                <li>Assessing skill gaps.</li>
              </ul>
            </div>
            <h2 className="yellow-text mt-5">
              DIGITAL TRANSFORMATION ROADMAPPING
            </h2>
            <div className="yellow_list col-12">
              <ul>
                <li>
                  Modernizing the existing technology stack to better align it
                  with business objectives and optimize the cost of ownership:
                  software re-architecting, re-coding, platform migration,
                  containerization.
                </li>
                <li>
                  Modernizing the IT infrastructure (e.g., managing the
                  migration of IT resources and digital assets to the cloud).
                </li>
                <li>
                  Integrating new digital tools optimal in terms of time and
                  financial investment.
                </li>
                <li>
                  Digitizing key processes, increasing a share of automated task
                  fulfillment.
                </li>
                <li>Reskilling your employees.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 row px-5">
            <div className="col-12 row d-flex align-items-center my-5">
              <div className="col-12 col-sm-3 image_container">
                <img src={images.misc.consult[5]}></img>
              </div>
              <div className="col-12 col-sm-9 mt-3">
                <h2 className="ms-3">IT strategy consulting</h2>
              </div>
            </div>
            <h2 className="yellow-text">SERVICE SCOPE</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>
                  Assessing the current state of your IT environment:
                  applications and IT infrastructure complexity, application
                  integrations, databases and data warehouses, development
                  infrastructure.
                </li>
                <li>
                  Shaping the vision of IT supporting business needs: IT
                  resources and their organization; cost-effective, stable, and
                  scalable infrastructure.
                </li>
                <li>
                  Planning strategic initiatives to achieve the desired state:
                  upgrade, modernization, migration,{" "}
                </li>
                <li>re-architecting activities.</li>
                <li>
                  Advancing the utilization of data resources for informed
                  decision-making.
                </li>
                <li>
                  Raising the efficiency of cloud resources, transit to
                  demand-driven cloud consumption.
                </li>
                <li>
                  Providing investment and time estimates for each initiative.
                </li>
                <li>Prioritizing the initiatives.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 row px-5">
            <div className="col-12 row d-flex align-items-center my-5">
              <div className="col-12 col-sm-3 image_container">
                <img src={images.misc.consult[4]}></img>
              </div>
              <div className="col-12 col-sm-9 mt-3">
                <h2 className="ms-3">IT solution consulting</h2>
              </div>
            </div>
            <h2 className="yellow-text">SERVICE SCOPE</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>
                  Analyzing your business requirements and scoping the solution
                  functionality.
                </li>
                <li>
                  Deciding between a platform-based and custom solution (taking
                  into account the budget, number of users, the existing
                  software ecosystem, and more factors).
                </li>
                <li>
                  If a platform-based solution is a better choice, we provide a
                  comparison research of relevant platforms, make a grounded
                  choice of the best one, and create functional specification
                  with configuration, customization and integration details.
                </li>
                <li>
                  If a custom solution is chosen, we plan a resilient
                  architecture, prototype the solution, and define the required
                  project skills and roles.
                </li>
              </ul>
            </div>
            <h2 className="yellow-text mt-5">SOFTWARE CATEGORIES WE COVER</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>Digital channels.</li>
                <li>Industrial processes.</li>
                <li>Internal business processes.</li>
                <li>Data analytics.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 row px-5">
            <div className="col-12 row d-flex align-items-center my-5">
              <div className="col-12 col-sm-3 image_container">
                <img src={images.misc.consult[6]}></img>
              </div>
              <div className="col-12 col-sm-9 mt-3">
                <h2 className="ms-3">Technology consulting</h2>
              </div>
            </div>
            <h2 className="yellow-text">SERVICE FOCUS</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>
                  Estimating the value your technology of choice may bring:
                  improvement of staff productivity, cost savings, competitive
                  edge, and more.
                </li>
                <li>
                  Planning the roadmap of technology adoption, starting from
                  license choice to implementation specification.
                </li>
              </ul>
            </div>
            <h2 className="yellow-text mt-5">TECHNOLOGIES WE WORK WITH</h2>
            <div className="yellow_list col-12">
              <ul>
                <li>
                  Estimating the value your technology of choice may bring:
                  improvement of staff productivity, cost savings, competitive
                  edge, and more.
                </li>
                <li>
                  Planning the roadmap of technology adoption, starting from
                  license choice to implementation specification.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Key Principles We Follow" paragraph="" />

        <div className="col-12 px-5">
          <div className="row">
            <FIRMCARD
              image={images.misc.consult[3]}
              heading={"Fair pricing"}
              paragraph={
                "We apply a pricing model depending on the nature of the consulting service we render.\n\n\nFixed price – for time-bound services with clearly defined deliverables: feasibility study, solution prototyping, market research.\n\n\nTime & Material – for long-term involvements when the service scope can’t be estimated accurately at the start: operational review of an IT department, an IT audit, modernization of a bunch of business apps."
              }
            />
            <FIRMCARD
              image={images.misc.consult[7]}
              heading={"Clear value propositiong"}
              paragraph={
                "The landscape of IT technologies and innovations is vast and growing. \n\n\nWe help companies navigate it and make smart IT investments into areas with real profit potential.\n\n\n When planning an IT strategy, we communicate explicitly the value of changes, typically centered around cost reduction, workflow automation, expansion into new revenue channels or service quality improvement."
              }
            />
            <FIRMCARD
              image={images.misc.consult[0]}
              heading={"Assisted transition to better IT"}
              paragraph={
                "We provide a full-service package of assistance for IT projects.\n\n\n We plan, design, implement and continuously support changes envisaged in the IT strategy.\n\n\n You don’t have to bounce between several teams down the road of your transition to an optimized IT infrastructure or digitally transformed processes."
              }
            />
            <FIRMCARD
              image={images.misc.consult[1]}
              heading={"Fast time-to-value"}
              paragraph={
                "Offering an iterative approach for long-term transformational initiatives.\n\n\n We manage to start providing tangible business value as early as possible. \n\n\n Even first small steps towards a more consistent and balanced IT environment optimize operational expenses, like high maintenance costs for obsolete IT assets."
              }
            />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
