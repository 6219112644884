import { Routes, Route } from "react-router-dom";
import BLOCKCHAIN from "../screens/blockchain";
import CONTACT from "../screens/contact";
import CUSTOMSOFTWARE from "../screens/custom";
import DEVOPS from "../screens/devops";
import FULLSTACK from "../screens/fullstack";
import { useEffect } from "react";
import { useLocation } from "react-router";

// SCREENS
import Home from "../screens/home";
import INTERM from "../screens/interm";
import IOT from "../screens/iot";
import IP from "../screens/ip";
import MachineLearning from "../screens/machine";
import Mobile from "../screens/mobile";
import QUANTUM from "../screens/quantum";
import SystemENG from "../screens/system_eng";
import TECHNICAL from "../screens/tecnical";
import Uiux from "../screens/uiux";
import VR from "../screens/vr";
import ABOUT from "../screens/about";
import JobPart from "../screens/jobpart";
import Consulting from "../screens/consulting";
import ProductDesign from "../screens/productDesign";
import Faq from "../screens/faq";
import FullCycleDev from "../screens/fullCycleDev";
import Mainteance from "../screens/mainteance";
import Penetration from "../screens/penetration";
import PrivacyPolicy from "../screens/privacyPolicy";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default function Navigate() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iot" element={<IOT />} />
          <Route path="/ml" element={<MachineLearning />} />
          <Route path="/quantum" element={<QUANTUM />} />
          <Route path="/vr" element={<VR />} />
          <Route path="/custom" element={<CUSTOMSOFTWARE />} />
          <Route path="/tecnical" element={<TECHNICAL />} />
          <Route path="/interm" element={<INTERM />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/devops" element={<DEVOPS />} />
          <Route path="/fullstack" element={<FULLSTACK />} />
          <Route path="/ip" element={<IP />} />
          <Route path="/contact" element={<CONTACT />} />
          <Route path="/system" element={<SystemENG />} />
          <Route path="/blockchain" element={<BLOCKCHAIN />} />
          <Route path="/uiux" element={<Uiux />} />
          <Route path="/about" element={<ABOUT />} />
          <Route path="/jobpart" element={<JobPart />} />
          <Route path="/uiux" element={<Uiux />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/productdesign" element={<ProductDesign />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/fullcycledev" element={<FullCycleDev />} />
          <Route path="/mainteance" element={<Mainteance />} />
          <Route path="/penetration-testing" element={<Penetration />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ScrollToTop>
    </>
  );
}
