import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import SIDECARD from "../../components/side_card";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function IP() {
  return (
    <div className="container-fluid">
      <Banner image={images.banner.ipprotect}>
        <span>
          <h1 className="fs-1 yellow-text mb-4">IP Protection</h1>
          <p className="fs-4">
            At Devstack, we adopt all the stringent measures to protect and
            secure your intellectual property. We graph all the terms and
            conditions for safeguarding the authorities and rights of the users
            that are attuned to their data and information. We chart all the
            sophisticated policies and corporate standards that deliver a
            complete sense of security to the user’s accounts while taking into
            account the physical security as well.
          </p>
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Data Protection"
          paragraph="We very well understand that the capital values of your software products are directly dependent on 
          the IP address and their consecutive nature that is involved. Keeping the fact in minds, we deliver all 
          the sophisticated and legal policies that are intended to protect all your rightful interest."
        />

        <div className="col-12 p-5">
          <div className="row protection_wrapper">
            <div className="col-12 col-md-4 col-lg-6 my-4">
              <SIDECARD
                minHeight={"100%"}
                image={images.misc.ipCard[1]}
                title={"NDA signing"}
                text={`Needless to say, signing an NDA is something that is
          worthy and really important. We sign it and thus, deliver
          a complete commitment to protect and secure all the
          sensitive information that is being shared. While signing
          a candidature, we make sure that all employees sign it in
          a fair manner and must adhere to all the security
          standards.`}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-6 my-4">
              <SIDECARD
                minHeight={"100%"}
                image={images.misc.ipCard[2]}
                title={"Dispersed ecosystem"}
                text={`We run applications in the distributed environment for locating the data across multiple locations. Instead of segregating the data on the individual servers, make it a priority to
                store your data across the shared homogenous architecture that is being established at our authorized data centers.`}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-6 my-4">
              <SIDECARD
                minHeight={"100%"}
                image={images.misc.ipCard[3]}
                title={"Label relocation"}
                text={`We respect the expectations that are attuned to your products. Ownership is the key and to unlock it, we provide all the rights, policies and the IP addresses that your organization requires for initiating any kind of venture. As per ethics, we make sure that the employees and external parties’ signs agreements in a transparent way. Once done,
                we take into account all the IP details as provided.`}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-6 my-4">
              <SIDECARD
                minHeight={"100%"}
                image={images.misc.ipCard[0]}
                title={"Data demolition"}
                text={`The moment our systems retire, we subject all the data destruction measures. Before any such person steps out of the premises, all the disks and other devices are cleared by our security and IT engineers. We make the machine all the way new for reuse in just the right and correct way. In the case of hardware device errors, we vanish the disk and device right away.`}
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Security Measures" paragraph="" />
        <div className="col-12">
          <div className="row">
            <div className="col-md-4 col-sm-6 mt-3 d-flex flex-column align-items-center">
              <img src={images.misc.ip[0]} alt="IP ADDRESS" />
              <h3 className="my-4 fs-2 text-center">Access control Cards</h3>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 d-flex flex-column align-items-center">
              <img src={images.misc.ip[4]} alt="IP ADDRESS" />
              <h3 className="my-4 fs-2 text-center">Full Fire Protection</h3>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 d-flex flex-column align-items-center">
              <img src={images.misc.ip[2]} alt="IP ADDRESS" />
              <h3 className="my-4 fs-2 text-center">CCTV placements</h3>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 d-flex flex-column align-items-center">
              <img src={images.misc.ip[1]} alt="IP ADDRESS" />
              <h3 className="my-4 fs-2 text-center">Alternate power and battery sources</h3>
            </div>
            <div className="col-md-4 col-sm-6 mt-3 d-flex flex-column align-items-center">
              <img src={images.misc.ip[3]} alt="IP ADDRESS" />
              <h3 className="my-4 fs-2 text-center">Role-based admittance</h3>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
