export default function SIDECARD(props) {
  return (
    <div
      className="card protection_card"
      style={{ minHeight: `${props.minHeight}` }}
    >
      <div className="card-body">
        <div className="col-12 mt-5 px-3">
          <img src={props.image} alt="Protextion" />
        </div>
        <div className="col-12 px-3 content">
          <h2 className="card-title mb-3">{props.title}</h2>
          <p className="fs-4">{props.text}</p>
        </div>
      </div>
    </div>
  );
}
