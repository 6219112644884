export default function SERVICE_CARD(props) {
  return (
    <>
      <div
        className="card service-card"
        style={{ minHeight: `${props.minHeight}` }}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <img src={props.image} alt="service" />
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8  card_content">
            <h2 className="service_card_title">{props.title}</h2>
            <p className="service_card_desc">{props.text}.</p>
          </div>
        </div>
      </div>
    </>
  );
}
