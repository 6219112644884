import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function FULLSTACK() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>Full Stack Development</title>
            <meta name="description" content="Devstack Soft Solution is a Toronto-based blockchain consultancy, specializing in smart contract development, Ethereum development, and cryptocurrency, provides blockchain security services to companies that want to explore this new technology." />
            <meta name='Keywords' content='fullstack development, fullstack blockchain development'/>
        </Helmet>
      <Banner image={images.banner.fullstack}>
        <h1 className="fs-1 yellow-text mb-4">Full-stack Development</h1>

        <h3 className="fs-4">
        As a <span className="yellow-text">Full-stack Development</span>{" "} solution provider, we offer a wide range of services to ensure cutting-edge, customized solutions that meet the needs of the product. We make every effort to develop apps, services, and websites that meet your expectations and meet the needs of your product.
        </h3>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>

      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="We offer comprehensive and professional 
        support at every stage of development "
          paragraph=""
        />

        <div className="col-12 p-5">
          <div className="row">
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.one}
              heading={"Discover & Estimate"}
              paragraph={
                "We collect and analyze your requirements in order to solve the problem and provide the best solution while keeping time and budget constraints in mind. We are ready to begin the journey as soon as we receive your main and agreed-upon app features and design."
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.two}
              heading={"Design & Clickable prototype"}
              paragraph={
                'We proceed to the creation of clickable prototypes based on the expertise gained in the first stage. We will ensure that you receive a couple of layouts so that you can choose the best way to implement them into UI/UX design.'
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.three}
              heading={"App development"}
              paragraph={
                'Design Creation is inextricably linked to the development process. We divide development into sprints so that we can show you the results of our work and get your feedback at every touchpoint.'
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.four}
              heading={"Testing"}
              paragraph={
                'For flawless app releases, quality assurance is required. We assign a team of experts to fix any potential bugs and defects and ensure your software runs smoothly.'
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.four}
              heading={"Deployment"}
              paragraph={
                'We Are Ready To Show Your Product To The World And Deliver The Most Enjoyable User Experience For Your Customers Once All Work Is Completed.'
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.service.five}
              heading={"Maintenance"}
              paragraph={
                'After the product is released, we will continue to support and improve your app in order to meet the needs of demanding end users in the future. Our team is always available to assist you with any issue at any time.'
              }
            />
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.node} />
            <TECH_CARD image={images.tech.elctron} />
            <TECH_CARD image={images.tech.next} />
            <TECH_CARD image={images.tech.mongo} />
            <TECH_CARD image={images.tech.amazons3} />
            <TECH_CARD image={images.tech.elastic} />
            <TECH_CARD image={images.tech.graphql} />
            <TECH_CARD image={images.tech.django} />
            <TECH_CARD image={images.tech.angular} />
            <TECH_CARD image={images.tech.hadoop} />
            <TECH_CARD image={images.tech.socket} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
