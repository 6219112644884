import Banner from "../../components/banner";
import Button from "../../components/button";
import DETAIL_CARD_1 from "../../components/detail_card_1";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ROUND_CARD_1 from "../../components/round_card_1";

export default function Uiux() {
  return (
    <div className="container-fluid about-page">
      <Banner image={images.banner.AboutUs}>
        <h1 className="fs-1 yellow-text">About Us</h1>
        <h5 className="mt-4 fs-2">Innovative. Reliable. Curious</h5>
        <h5 className="mt-4 fs-2">
          Meet the company that you will enjoy working with.
        </h5>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="about-second-sec col-12 row px-5 d-flex justify-content-md-end align-items-md-center justify-content-center">
          <div className="col-12 col-md-8 row d-flex align-items-center">
            <div className="col-12 row px-5">
              <div className="col-12 col-md-6  d-flex align-items-center">
                <h1 className="fs-1 yellow-text fw-light">How it started</h1>
              </div>
              <div className="col-12 col-md-6 ">
                <h2 className="fs-4 fw-light">
                  It all started when a 23 year old Blockchain Developer thought
                  of sharing his skillsets and experience to create Scalable,
                  Secure and Futuristic softwares and to bring a digital
                  transformation in the current Professional environment.
                </h2>
              </div>
            </div>
            <div className="col-12 row px-5 mt-5">
              <div className="col-12 col-md-6 d-flex align-items-center">
                <h1 className="fs-1 yellow-text fw-light">How it’s going</h1>
              </div>
              <div className="col-12 col-md-6">
                <h2 className="fs-4 fw-light">
                  Currently, more than 5 people at Devstack diligently strive to
                  maintain a high quality of work and continue delivering
                  bespoke solutions powered by Blockchain, DevOps, Machine
                  Learning and other rising technologies.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-8 col-md-4  ">
            <img
              src={images.banner.officeworkdesk}
              style={{ width: "100%" }}
            ></img>
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      {/* <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="about-team-sec col-12 row px-5 ">
          <h1 className="fs-1 ">Our Team</h1>
          <div className="  col-md-6 col-sm-12 d-flex flex-column align-items-center mt-5">
            <div className="about-team-sec_pic-circle"></div>

            <br />
            <h2>Pratik Patil</h2>
            <br />
            <Button text="Founder and CEO" />
          </div>
        </div>
      </AnimationOnScroll> */}
      <br />
      <br id="culture"/>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 row px-5 mt-5">
          <div className="yellow_list col-md-6 col-sm-12 ">
            <h1 className="fs-1 ">Our Culture</h1>
            <h2 className="mt-5 fs-3 fw-light">
              At Devstack, we embody learning and caring culture, attracting the
              best talent, who stay with us and our customers for years. That’s
              how we live, learn, grow, and succeed together. We are motivated
              experts seeking out challenges. We look for partners, rather than
              just customers.
            </h2>
            <h2 className="mt-4 fs-3 fw-light">
              We’re aimed at building long-lasting business relationships based
              on mutual understanding and trust.
            </h2>
          </div>
          <div className="col-md-6 col-sm-12 gif-image d-flex justify-content-center justify-content-md-end">
            <img
              src={images.banner.developerYoga}
              //   className={"img-fluid"}
              alt="custom software"
              height={300}
              width={300}
            />
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Whom we serve" />

        <div className="col-12 p-5" style={{ marginTop: "5rem" }}>
          <div className="row ">
            <div
              className="col-12 col-md-6 d-flex justify-content-center align-items-center"
              style={{ marginBottom: "8rem" }}
            >
              <DETAIL_CARD_1
                minHeight="100%"
                image={images.misc.about[2]}
                title={"Startups"}
                text={
                  "Systematic streamlined operations that prepare the launch of Startup to an Enterprise. We do the product development for start-ups and make them scalable to get more funding as a brand."
                }
              />
            </div>
            <div
              className="col-12 col-md-6 d-flex justify-content-center align-items-center"
              style={{ marginBottom: "8rem" }}
            >
              <DETAIL_CARD_1
                minHeight="100%"
                image={images.misc.about[0]}
                title={"Small & Medium Business"}
                text={
                  "Web and Mobile App Development for the better launch of your business into the global market made possible with the right approach and exposure of technology."
                }
              />
            </div>
            <div
              className="col-12 col-md-6 d-flex justify-content-center align-items-center"
              style={{ marginBottom: "8rem" }}
            >
              <DETAIL_CARD_1
                minHeight="100%"
                image={images.misc.about[3]}
                title={"Product Envisioners"}
                text={
                  "Systematic streamlined operations that prepare the launch of Startup to an Enterprise. We do the product development for start-ups and make them scalable to get more funding as a brand."
                }
              />
            </div>
            <div
              className="col-12 col-md-6 d-flex justify-content-center align-items-center"
              style={{ marginBottom: "8rem" }}
            >
              <DETAIL_CARD_1
                minHeight="100%"
                image={images.misc.about[1]}
                title={"Enterprise"}
                text={
                  "Robust and pragmatic customised enterprise mobility solutions or web solutions that best meet your enterprise needs, each layer engineered with experience and expertise."
                }
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
