import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import TECH_CARD from "../../components/tech_cards";
import DETAIL_CARD_2 from "../../components/detail_card_2";
import DETAIL_CARD from "../../components/detail_card";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Helmet from "helmet";

export default function CONTACT() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>Penetration Testing</title>
            <meta name="description" content="Security assessments should be a standard part of any organization's security strategy. Our penetration testing software can help you identify weaknesses and vulnerabilities in your IT infrastructure, so you can take steps to protect against cyberattacks." />
            <meta name='Keywords' content='penetration testing'/>
        </Helmet>
      <Banner image={images.banner.cyberSecurity}>
        <h1 className="fs-1 yellow-text fw-light mb-4">Penetration Testing</h1>
        <h2 className="fs-4 fw-light">
        We assist organizations in understanding the security of their network from both an internal and external perspective, and we use a variety of manual and automated enumeration techniques to systematically compromise systems in scope in order to establish the current landscape.
        </h2>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="What we offer" paragraph="" />

        <div className="col-12 p-md-5 row penetration-testing-offer-sec">
          <div className="col-12 col-md-4 d-flex justify-content-center">
            <DETAIL_CARD_2
              minHeight="100%"
              image={images.misc.yellowRectangle1}
              text={
                "Crest-Certified Penetration Testing Experts With Security Clearance Who Can Conduct An Independent Assessment Of Your Critical Applications And Infrastructure Using Network Penetration Tests."
              }
            />
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center">
            <DETAIL_CARD_2
              minHeight="100%"
              image={images.misc.yellowRectangle1}
              text={
                "We ensure that you understand what is required by providing detailed remediation guidance to ensure that you achieve the appropriate assurance."
              }
            />
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center">
            <DETAIL_CARD_2
              minHeight="100%"
              image={images.misc.yellowRectangle1}
              text={
                "Important Before Beginning Any Testing Prior to agreeing to any services, we conduct a scoping exercise to ensure that you are provided with the appropriate testing methodology."
              }
            />
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center">
            <DETAIL_CARD_2
              minHeight="100%"
              image={images.misc.yellowRectangle1}
              text={
                "Different Penetration Tests and Awareness Training."
              }
            />
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Available Crest-Accredited Penetration Testing Services"
          paragraph=""
        />

        <div className="col-12 p-md-5">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-5">
              <DETAIL_CARD
                minHeight="100%"
                image={images.misc.penet[0]}
                title={"Infrastructure Penetration Tests"}
                text={
                  "Infrastructure Penetration Testing Is A Methodology For Evaluating The Security Of An Organization's Systems And/Or Network To Ensure They Are Designed, Configured, And Managed In A Way That Protects Your Critical Assets From Malicious Attacks. You only need to read the news to realise that the cyber threat is ever present and can take many forms, such as phishing attacks or ransomware attacks."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-5">
              <DETAIL_CARD
                minHeight="100%"
                image={images.misc.penet[2]}
                title={"Application Penetration Tests"}
                text={
                  "Web applications have become critical to organizations all over the world, allowing them to reach out to global consumers and operate internationally with ease. They are also the company's public face and, in some cases, the first point of contact a customer has with the organization. It Is Thus Critical That These Applications Are Adequately Tested Against Malicious Actors, Whether To Ensure The Protection Of Personal And/Or Sensitive Data Or To Ensure Your Websites Stay Up And Running."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-5">
              <DETAIL_CARD
                minHeight="100%"
                image={images.misc.penet[1]}
                title={"Mobile Security Penetration Tests"}
                text={
                "Smartphones Are Increasingly Being Used to Help Organizations Operate More Effectively in Every Location. Mobiles Must Now Be Considered As A Key Access Point To An Organization's Environment, As Hackers Turn Their Attention To Mobiles. If a mobile application is important to your organization's strategy, then our mobile application penetration testing is required."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-5">
              <DETAIL_CARD
                minHeight="100%"
                image={images.misc.penet[3]}
                title={"Wireless Security Penetration Tests"}
                text={
                  "Wireless Security Is Inherently Insecure Under The 802.11 Standard. Our Penetration Testers Can Assist Your Organization In Evaluating The Security Of Your Wireless Infrastructure And Supporting Procedures. We will test your wireless network infrastructure, identify security issues, and assess its reliability."
                }
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5 tech_wrapper">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.metas} />
            <TECH_CARD image={images.tech.zmap} />
            <TECH_CARD image={images.tech.aircrack} />
            <TECH_CARD image={images.tech.nessus} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
