import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function TECHNICAL() {
  return (
    <div className="container-fluid">
      <Banner image={images.banner.technical}>
        <span>
          <h1 className="fs-1 yellow-text mb-4">Technical Assessment</h1>
          <span className="mt-3">
            Evaluate your company's software delivery capabilities. Prioritise
            the high-impact changes that will help your product grow.
          </span>
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Technical Assessment Outcomes"
          paragraph="The technical assessment will result in a clear strategic direction, as well as a list of high-priority action items, such as risks, opportunities, and quick wins."
        />
        <Title
          heading="Technical Assessment Activities"
          paragraph="Our consultants will use a combination of these activities, depending on the breadth of the technical evaluation and 
        the demands of your team:"
        />

<br/>
      <br/>
        <div className="col-12 row px-5">
          <h2 className="yellow-text">Observe, Measure, and Review</h2>
          <div className="yellow_list col-12">
            <ul>
              <li>Mapping value Streams </li>
              <li>Architecture & Code Review</li>
              <li>Technology assessment</li>
              <li>Metrics and Quantitative Analysis</li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>
      <br/>
      <br/>
      <br/>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 row px-5">
          <h2 className="yellow-text">Present Findings</h2>
          <div className="yellow_list col-12">
            <ul>
              <li>Personalized Report </li>
              <li>Executive Summary</li>
              <li>Technology assessment</li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Technical Assessment Team"
          paragraph="We customize our workforce to meet your specific requirements. For a technical evaluation, a Devstack team will often use seasoned Lead and Principal consultants that specialize in product and engineering."
        />
        <div className="col-12 row px-5">
          <div className="yellow_list col-12">
            <ul>
              <li>
                Our teams often introduce experienced lead and main consultants
                who specialize in products and technology for technical reviews.
              </li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Duration"
          paragraph="The duration of a technical assessment is determined by a few key factors:"
        />
        <div className="col-12 row px-5">
          <div className="yellow_list col-12 mb-4">
            <ul>
              <li>The product’s complexity</li>
              <li>The focus of the assessment's scope</li>
              <li>Medium of the report recommendations</li>
            </ul>
          </div>
          <p className="fs-4">
            The technical assessments usually range from three to six weeks.
          </p>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
