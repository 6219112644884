import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import DETAIL_CARD from "../../components/detail_card";
import Helmet from "helmet";

import FIRMCARD from "../../components/firmCard";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function PRODUCTDESIGN() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>Product Design</title>
            <meta name="description" content="Finding the best software development company for your business is a challenge. It may be hard to know where to start, or even what to look for. However, with our expertise in the field and years of experience, we can work with you to find." />
            <meta name='Keywords' content='product design, product architect'/>
        </Helmet>
      <Banner image={images.banner.presenting}>
        <h1 className="fs-1 yellow-text mb-4">Product Design</h1>
        <p className="fs-4">
          Without goals, and plans to reach them, you are like a ship that has
          set sail with no destination.
        </p>
        <p className="fs-4">
          We work along with your team to design impactful digital products that
          meet your users’ needs.
        </p>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Product Design is:" paragraph="" />

        <div className="col-12 p-5">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-4 my-4">
              <DETAIL_CARD
                minHeight={"35rem"}
                image={images.misc.product[4]}
                title={"Architecture"}
                text={
                  "Detailed project structure and strategy that helps to get an idea of how all elements are combined and the way the project will be implemented."
                }
              />
            </div>
            <div className="col-12 col-lg-4 col-md-4 my-4">
              <DETAIL_CARD
                minHeight={"35rem"}
                image={images.misc.product[6]}
                title={"Specification"}
                text={
                  "Creating the user stories we consider only the best experience in your future product development."
                }
              />
            </div>
            <div className="col-12 col-lg-4 col-md-4 my-4">
              <DETAIL_CARD
                minHeight={"35rem"}
                image={images.misc.product[5]}
                title={"Estimation"}
                text={
                  "An evaluation of the project is needed to forecast the workload that should be done considering budget and timeline expectations."
                }
              />
            </div>
            <div className="col-12 col-lg-4 col-md-4 my-4">
              <DETAIL_CARD
                minHeight={"35rem"}
                image={images.misc.product[7]}
                title={"Design"}
                text={
                  "An interactive prototype of all pages that gives you a chance to have a look and taste a flavor of your idea."
                }
              />
            </div>
            <div className="col-12 col-lg-4 col-md-4 my-4">
              <DETAIL_CARD
                minHeight={"35rem"}
                image={images.misc.product[8]}
                title={"Road map"}
                text={
                  "A strategic plan that set a goal or desired result including main steps or milestones that is needed for successful project."
                }
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="To get your project underway simply
          trust your idea to the team of experts"
          paragraph="How it is implemented?"
        />
        <div className="row  p-5">
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round big">
                <img src={images.misc.product[0]}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-light">
                  UI/UX Design Clickable dynamic prototype.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round big">
                <img src={images.misc.product[1]}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-light">Cost of project and duration.</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round big">
                <img src={images.misc.product[2]}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-light">
                  Technical structure of future product in schemas which
                  describe how the product works.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round big">
                <img src={images.misc.product[3]}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-light">
                  Document with detail features which show functionalities of
                  the product.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="How you benefit your business with 
          Product Design?"
          paragraph=""
        />
        <div className="row  p-5">
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round bigNumber">
                <img src={images.service.one}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-bold">
                  Deep analysis and elaboration of the project
                </h2>
                <span className="fs-4 fw-light">
                  We use innovative technologies such as blockchain and AI.
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round bigNumber">
                <img src={images.service.two}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-bold">
                  Reliable predictions of timelines and budgets
                </h2>
                <span className="fs-4 fw-light">
                  Making budgeting planning is the most effective way to keep
                  your business finances and records on track.
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round bigNumber">
                <img src={images.service.three}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-bold">Project roadmap</h2>
                <span className="fs-4 fw-light">
                  Knowing your project goals and objectives is a half way of
                  reaching final destination.
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5 ">
            <div className="col-12 row d-flex align-items-center __consulting">
              <div className="col-12 col-sm-4 image_container round bigNumber">
                <img src={images.service.four}></img>
              </div>
              <div className="col-12 col-sm-8">
                <h2 className="fs-2 fw-bold">Initiation documentations</h2>
                <span className="fs-4 fw-light">
                  Our team do it’s best to supply you with needed documentations
                  for successful start of your project.
                </span>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
