export default {
    service_id: 'devstack_email',
    template_id: 'template_hf0huya',
    user_id: 'biMzZXM_V0pj7k_rY',
    metadata: {
    from_name: "Devstack Email",
    to_name: "Info Devstack",
    message: "Website Inquiry",
    reply_to: "info@devstacksoftsolutions.com",
    }
}