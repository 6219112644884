const images = {
  logo: require("../assets/images/logo.png"),
  main: [
    require("../assets/images/main/1.png"),
    require("../assets/images/main/2.png"),
    require("../assets/images/main/3.png"),
    require("../assets/images/main/4.png"),
    require("../assets/images/main/5.png"),
    require("../assets/images/main/6.png"),
    require("../assets/images/main/7.png"),
  ],
  chu: [
    require("../assets/images/banner/chu (1).gif"),
    require("../assets/images/banner/chu (2).gif"),
    require("../assets/images/banner/chu (3).gif"),
    require("../assets/images/banner/chu (4).gif"),
    require("../assets/images/banner/chu (5).gif"),
    require("../assets/images/banner/chu (6).gif"),
    require("../assets/images/banner/chu (7).gif"),
    require("../assets/images/banner/chu (8).gif"),
    require("../assets/images/banner/chu (9).gif"),
  ],
  banner: {
    home: require("../assets/images/banner/87289-defi-farming.gif"),
    iot: require("../assets/images/banner/74036-iot-transparent.gif"),
    machineLearning: require("../assets/images/banner/82573-ai-machine.gif"),
    quantum: require("../assets/images/banner/80051-innovation-2.gif"),
    vr: require("../assets/images/banner/72266-vr-learning.gif"),
    technical: require("../assets/images/banner/17784-analyzing-website.gif"),
    interm: require("../assets/images/banner/89965-developer-team.gif"),
    mobile: require("../assets/images/banner/107291-mobileeeeeee.gif"),
    devops: require("../assets/images/banner/93156-development-icon.gif"),
    fullstack: require("../assets/images/banner/44003-web-development.gif"),
    contact: require("../assets/images/banner/88474-contact.gif"),
    eng: require("../assets/images/banner/84855-engineering-infrastructure.gif"),
    uiuxDesign: require("../assets/images/banner/90586-graphic-designer 1.png"),
    AboutUs: require("../assets/images/banner/76817-contact-us 1.png"),
    developerYoga: require("../assets/images/banner/82423-developer-yoga.gif"),
    officeworkdesk: require("../assets/images/banner/27432-developer.gif"),
    readyToWork: require("../assets/images/banner/41390-recruitment-get-ready-to-work-job-recruitment-isometric-hiring-process.gif"),
    uiuxDesign: require("../assets/images/banner/90586-graphic-designer 1.png"),
    AboutUs: require("../assets/images/banner/76817-contact-us.gif"),
    developerYoga: require("../assets/images/banner/82423-developer-yoga.gif"),
    consulting: require("../assets/images/banner/83386-consulting-in-blue.gif"),
    presenting: require("../assets/images/banner/75941-design-process.gif"),
    faq: require("../assets/images/banner/85626-faq1.gif"),
    webDevelopment: require("../assets/images/banner/64110-web-development.gif"),
    customerSupport: require("../assets/images/banner/41384-customer-support-animation.gif"),
    cyberSecurity: require("../assets/images/banner/29826-cybersecurity-animation.gif"),
    uiux: require("../assets/images/banner/uiux.gif"),
    ipprotect: require("../assets/images/banner/83007-lock-shield-protection.gif"),
    tab: [
      require("../assets/images/banner/balancing-scales.gif"),
      require("../assets/images/banner/tab2.png"),
      require("../assets/images/banner/85353-business-lottie-animation.gif"),
      require("../assets/images/banner/tab4.png"),
      require("../assets/images/banner/t1.png"),
    ],
  },
  service: {
    blockchain: [
      require("../assets/images/services/f3e081cd17c01cb16fefcb07ef81c1bc.png"),
      require("../assets/images/services/1bda92309d6c98ea5b4e6ecb69895596.png"),
      require("../assets/images/services/96787ce4c7e788905ba3ac90887d47df.png"),
      require("../assets/images/services/2a90b4c9b2f3fc7b3ff9fc4f3b64007a.png"),
    ],
    mobile: [
      require("../assets/images/services/clock 1.png"),
      require("../assets/images/services/image 34.png"),
      require("../assets/images/services/image 35.png"),
    ],
    iot: [require("../assets/images/banner/55016-mesh-network.gif")],
    ml: [
      require("../assets/images/banner/Group 387.gif"),
      require("../assets/images/banner/SentimentalThickBullfrog-size_restricted 1.gif"),
    ],
    quantum: [require("../assets/images/banner/64510-filtro-quantum.gif")],
    custom: [
      require("../assets/images/banner/mobile_development2.png"),
      require("../assets/images/banner/74112-cloud.gif"),
    ],
    vr: [require("../assets/images/banner/79933-virtual-reality.gif")],
    tick: require("../assets/images/misc/tick.png"),
    one: require("../assets/images/misc/1.png"),
    two: require("../assets/images/misc/2.png"),
    three: require("../assets/images/misc/3.png"),
    four: require("../assets/images/misc/4.png"),
    five: require("../assets/images/misc/5.png"),
  },
  features: {
    videoEditing: require("../assets/images/features/video-editing.png"),
    branding: require("../assets/images/features/branding.png"),
    ux: require("../assets/images/features/ux.png"),
  },
  header: {
    blockchain: require("../assets/images/headers/72a449683dfe58c3babe81a2788d03cd.png"),
    iot: require("../assets/images/headers/2953567686a2ccbe94cba53c14a2d481.png"),
    ml: require("../assets/images/headers/dc1d7f2caf6dcf7a87dd937dc7e8d9e5.png"),
    quantum: require("../assets/images/headers/df889e99dbcdc7043fcd85686804f8bf.png"),
    cloud: require("../assets/images/headers/031d0eb34aa971c23ded55de5c9b23a1.png"),
    penetration: require("../assets/images/headers/4c0e0f9ebbcf253775207154670a3969.png"),
    vr: require("../assets/images/headers/8fb66fc244623fc3c986f5f041c1e000.png"),
    fs: require("../assets/images/headers/29398217ad9ad4670aea787cc12dfb73.png"),
    md: require("../assets/images/headers/7085f909d441889fc9001b1746eba743.png"),
    uiux: require("../assets/images/headers/813ca9fc3e6e987a8d374a8c00f10e2e.png"),
    se: require("../assets/images/headers/c431cc4554e06a6f890e08980a2d8c03.png"),
    doe: require("../assets/images/headers/86c681f622b69fd137c6b842104e17f5.png"),
    pd: require("../assets/images/headers/a7b2f513cf7051f4f12414d2b1107b69.png"),
    fcd: require("../assets/images/headers/a7886680aab38c1d36b9084d913268c9.png"),
    consult: require("../assets/images/headers/a03fa3ad1c1ad50f0ad511f4fb61371a.png"),
    mas: require("../assets/images/headers/5117076e763b0be238dea215fdc0e5a0.png"),
    article: require("../assets/images/headers/3eaae073754b16d7872a6487a6dbba51.png"),
    faq: require("../assets/images/headers/3277ddc6cd1c8962f27fbb27f6964522.png"),
    cs: require("../assets/images/headers/788ae71748db66207dacd9a28a51b848.png"),
  },
  misc: {
    map: require("../assets/images/misc/map.png"),
    IND: require("../assets/images/misc/india-flag.png"),
    CA: require("../assets/images/misc/canada-flag.png"),
    location: require("../assets/images/misc/location.png"),
    envolap: require("../assets/images/misc/envolap.png"),
    envolapBlue: require("../assets/images/misc/envolapBlue.png"),
    projectmanagement: require("../assets/images/misc/project-management 1.png"),
    reliability: require("../assets/images/misc/reliability 1.png"),
    results: require("../assets/images/misc/results 1.png"),
    rocket: require("../assets/images/misc/rocket 1.png"),
    busybrain: require("../assets/images/misc/busybrain.png"),
    transparency: require("../assets/images/misc/transparency 1.png"),
    star: require("../assets/images/misc/image 176.png"),
    bugFixing: require("../assets/images/misc/bugFixing.png"),
    currentTrends: require("../assets/images/misc/currentTrends.png"),
    cutCosts: require("../assets/images/misc/cutCosts.png"),
    performanceImprovement: require("../assets/images/misc/performanceImprovement.png"),
    yellowRectangle1: require("../assets/images/misc/yellowRectangle1.png"),

    ml: [
      require("../assets/images/misc/ml-1.png"),
      require("../assets/images/misc/ml-2.png"),
      require("../assets/images/misc/ml-3.png"),
      require("../assets/images/misc/ml-4.png"),
      require("../assets/images/misc/ml-5.png"),
      require("../assets/images/misc/ml-6.png"),
    ],

    vr: [
      require("../assets/images/misc/vr (1).png"),
      require("../assets/images/misc/vr (2).png"),
      require("../assets/images/misc/vr (3).png"),
      require("../assets/images/misc/vr (4).png"),
      require("../assets/images/misc/vr (5).png"),
    ],
    penet: [
      require("../assets/images/misc/penet (1).png"),
      require("../assets/images/misc/penet (2).png"),
      require("../assets/images/misc/penet (3).png"),
      require("../assets/images/misc/penet (4).png"),
    ],
    maintain: [
      require("../assets/images/misc/maintain (1).png"),
      require("../assets/images/misc/maintain (2).png"),
      require("../assets/images/misc/maintain (3).png"),
      require("../assets/images/misc/maintain (4).png"),
      require("../assets/images/misc/maintain (5).png"),
      require("../assets/images/misc/maintain (6).png"),
    ],
    cycle: [
      require("../assets/images/misc/cycle (1).png"),
      require("../assets/images/misc/cycle (2).png"),
      require("../assets/images/misc/cycle (3).png"),
    ],
    product: [
      require("../assets/images/misc/product (1).png"),
      require("../assets/images/misc/product (2).png"),
      require("../assets/images/misc/product (3).png"),
      require("../assets/images/misc/product (4).png"),
      require("../assets/images/misc/product (5).png"),
      require("../assets/images/misc/product (6).png"),
      require("../assets/images/misc/product (7).png"),
      require("../assets/images/misc/product (8).png"),
      require("../assets/images/misc/product (9).png"),
    ],
    consult: [
      require("../assets/images/misc/consult (1).png"),
      require("../assets/images/misc/consult (2).png"),
      require("../assets/images/misc/consult (3).png"),
      require("../assets/images/misc/consult (4).png"),
      require("../assets/images/misc/consult (5).png"),
      require("../assets/images/misc/consult (6).png"),
      require("../assets/images/misc/consult (7).png"),
      require("../assets/images/misc/consult (8).png"),
    ],
    about: [
      require("../assets/images/misc/about (1).png"),
      require("../assets/images/misc/about (2).png"),
      require("../assets/images/misc/about (3).png"),
      require("../assets/images/misc/about (4).png"),
    ],
    mobile: [
      require("../assets/images/misc/mobile (1).png"),
      require("../assets/images/misc/mobile (2).png"),
      require("../assets/images/misc/mobile (3).png"),
      require("../assets/images/misc/mobile (4).png"),
      require("../assets/images/misc/mobile (5).png"),
      require("../assets/images/misc/mobile (6).png"),
    ],

    qntm: [
      require("../assets/images/misc/q (1).png"),
      require("../assets/images/misc/q (2).png"),
      require("../assets/images/misc/q (3).png"),
      require("../assets/images/misc/q (4).png"),
    ],

    blockchain: [
      require("../assets/images/misc/chain1.png"),
      require("../assets/images/misc/chain2.png"),
      require("../assets/images/misc/chain3.png"),
    ],
    round: [
      require("../assets/images/misc/round1.png"),
      require("../assets/images/misc/round2.png"),
      require("../assets/images/misc/round3.png"),
      require("../assets/images/misc/round4.png"),
      require("../assets/images/misc/round5.png"),
    ],
    small: [
      require("../assets/images/misc/small1.png"),
      require("../assets/images/misc/small2.png"),
      require("../assets/images/misc/small3.png"),
      require("../assets/images/misc/small4.png"),
    ],
    home: [
      require("../assets/images/misc/c1.png"),
      require("../assets/images/misc/c2.png"),
      require("../assets/images/misc/c3.png"),
      require("../assets/images/misc/c4.png"),
    ],
    timeline: [
      require("../assets/images/misc/timeline1.png"),
      require("../assets/images/misc/timeline2.png"),
      require("../assets/images/misc/timeline3.png"),
      require("../assets/images/misc/timeline4.png"),
      require("../assets/images/misc/timeline5.png"),
      require("../assets/images/misc/timeline6.png"),
      require("../assets/images/misc/timeline7.png"),
      require("../assets/images/misc/timeline8.png"),
      require("../assets/images/misc/timeline9.png"),
    ],
    im: [
      require("../assets/images/misc/im1.png"),
      require("../assets/images/misc/im2.png"),
      require("../assets/images/misc/im3.png"),
      require("../assets/images/misc/im4.png"),
      require("../assets/images/misc/im5.png"),
      require("../assets/images/misc/im6.png"),
    ],
    number: [
      require("../assets/images/misc/number1.png"),
      require("../assets/images/misc/number2.png"),
      require("../assets/images/misc/number3.png"),
      require("../assets/images/misc/number4.png"),
    ],
    iot: [
      require("../assets/images/misc/firmware.png"),
      require("../assets/images/misc/ashddasl.png"),
      require("../assets/images/misc/asdasdads.png"),
      require("../assets/images/misc/firmwarer.png"),
      require("../assets/images/misc/alskhdas.png"),
    ],
    ip: [
      require("../assets/images/misc/4fbacc1583737be7932e8b84d72bdbb7.png"),
      require("../assets/images/misc/9a2d903094fad32b677bb556d40fd95f.png"),
      require("../assets/images/misc/004294fa277f68f4947d1a36c56d8066.png"),
      require("../assets/images/misc/5205234fa215954d358c14876029487a.png"),
      require("../assets/images/misc/cb1c586114b7c9af3acc4d572516eb47.png"),
    ],
    ipCard: [
      require("../assets/images/misc/image.png"),
      require("../assets/images/misc/blah.png"),
      require("../assets/images/misc/blah2.png"),
      require("../assets/images/misc/blah3.png"),
    ],
    eng: [
      require("../assets/images/misc/beep.png"),
      require("../assets/images/misc/beep2.png"),
      require("../assets/images/misc/beep3.png"),
      require("../assets/images/misc/beep4.png"),
    ],
    engCard: [
      require("../assets/images/misc/deep.png"),
      require("../assets/images/misc/deep2.png"),
      require("../assets/images/misc/deep3.png"),
      require("../assets/images/misc/deep4.png"),
    ],
    attachment: require("../assets/images/misc/attachment.png"),
  },

  tech: {
    nessus: require("../assets/images/tech/nessus.png"),
    zmap: require("../assets/images/tech/zmap.png"),
    metas: require("../assets/images/tech/metas.png"),
    aircrack: require("../assets/images/tech/aircrack.png"),
    socket: require("../assets/images/tech/socket.jpg"),
    qt: require("../assets/images/tech/38b386afc9f6e4b1e32c221a78c5ca2c.png"),
    mongo: require("../assets/images/tech/mongo.png"),
    elastic: require("../assets/images/tech/elastic.png"),
    graphql: require("../assets/images/tech/graphql.png"),
    angular: require("../assets/images/tech/angular.png"),
    hadoop: require("../assets/images/tech/hadoop.png"),
    jenkins: require("../assets/images/tech/04c6555e064276892c8b8f3058b3da03.png"),
    native: require("../assets/images/tech/04e7eefd9facfda6e4daba8e13068dab.png"),
    openql: require("../assets/images/tech/4b44d3d23f5009551506b9cb14205eee.png"),
    android: require("../assets/images/tech/4c84874cd166076a0def2632e78f0b54.png"),
    polygon: require("../assets/images/tech/4e5c102524504912044d2e86c3e63153.png"),
    unity: require("../assets/images/tech/4f6beba45947f7adf4ec7fd22519f3ea.png"),
    swift: require("../assets/images/tech/6b219da1be5d8561e660c220ccdf2395.png"),
    tensorflow: require("../assets/images/tech/76aa643501d336a7059571b8eabfd1ff.png"),
    docker: require("../assets/images/tech/6d59728999924074483c71b6cf56bec6.png"),
    prometheus: require("../assets/images/tech/8b295f915a4610b0b3e92808180e6c9e.png"),
    node: require("../assets/images/tech/8cf13ea8f27f57c001b20c9f145ea9ef.png"),
    balsamiq: require("../assets/images/tech/09c31329fed902695267da2b357f3c37.png"),
    grafana: require("../assets/images/tech/9d07ecde4d5f44941cdeb4c7d5b007b2.png"),
    flutter: require("../assets/images/tech/14c040cdeed9312189febb15f8519dc7.png"),
    meta: require("../assets/images/tech/017eecc704ff8f6c9cf22d0dcb7c842b.png"),
    eos: require("../assets/images/tech/21f078a9d88e1777b627637867c20494.png"),
    brackets: require("../assets/images/tech/42e209d5c5a6427f0322f3f8e6d77e3f.png"),
    next: require("../assets/images/tech/44c7810cf447e076e70e7c1fdedc15b3.png"),
    inVision: require("../assets/images/tech/51ca514e472165b9c9638f4adc1f2056.png"),
    handBrake: require("../assets/images/tech/58d4f9f5b258f20ff15c2caa6fa7e81f.png"),
    kuberNote: require("../assets/images/tech/63acf1d622c2e147444388bb2821f490.png"),
    adobe: require("../assets/images/tech/72a953ed506fa4af27c6a86d69bd3c15.png"),
    steller: require("../assets/images/tech/74a545a5bd7841261a633ac8d836947f.jpg"),
    gimp: require("../assets/images/tech/91ca29879c91a5d435285e4cce541d39.png"),
    amazons3: require("../assets/images/tech/436b243d3dc54d3798fb32835fc0a169.png"),
    r3: require("../assets/images/tech/0457de10247fc3cb30a244d290108f74.png"),
    figma: require("../assets/images/tech/496e9e90de5249dc77b27a710704cca7.png"),
    valgrind: require("../assets/images/tech/498fd3ff775b623a6de4f0306be9950d.png"),
    clang: require("../assets/images/tech/565a1871e28be224d7a17d4ff1f72f73.png"),
    hyperledger: require("../assets/images/tech/660bb99631073306c075f507a086f469.png"),
    ardino: require("../assets/images/tech/719c40167bdc09bf2ca88d2f677f6d73.png"),
    matlab: require("../assets/images/tech/740d6f52f102ad941efd928747ccae6f.png"),
    angular: require("../assets/images/tech/5514b17935a5cb0d3efa06462aa3c5f9.png"),
    origami: require("../assets/images/tech/12803a6aadb325a71ba69dd569b842ad.png"),
    zaplin: require("../assets/images/tech/33486e22ec487078ec33a2937914df8a.png"),
    maya: require("../assets/images/tech/image 148.png"),
    keras: require("../assets/images/tech/9240427b8f6e50413ac4cd10c359b47e.png"),
    elctron: require("../assets/images/tech/a9e4697656dfd1d8f9340b367471cf99.png"),
    zigbee: require("../assets/images/tech/adc85bc4ce21e47bcd855f7a20c65c79.png"),
    adobexd: require("../assets/images/tech/b2ccb5ad72e0698b2915680fe67dfd2c.png"),
    rippple: require("../assets/images/tech/b7160f340c8d034e403f8d155a1a280a.jpg"),
    iota: require("../assets/images/tech/b7941c274fc48d74a4e3390d12041224.png"),
    eclipse: require("../assets/images/tech/c2486dbb86d171a24d63d5c04fce618c.png"),
    unreal: require("../assets/images/tech/cb292b243a5ec6ba35febff41ff04c8a.png"),
    clonezilla: require("../assets/images/tech/cfa64ac80fe1457a4a35fb25a350309c.png"),
    django: require("../assets/images/tech/d1a607e42c569fb4cc23f05b025be0d1.png"),
    clion: require("../assets/images/tech/d03e23ac87662dc4cdd799773078f4a3.png"),
    blender: require("../assets/images/tech/d9d67fba0e94034c0e17f234eb1f66b3.png"),
    pytorch: require("../assets/images/tech/d56fefd48eec0042ff7ed63327469819.png"),
    qiskit: require("../assets/images/tech/deaf3608f65a20164b2dfef1201ed128.png"),
    rasberryPi: require("../assets/images/tech/e700be8b42443ca7144fab185de5e305.png"),
    sketch: require("../assets/images/tech/ef35acafcf6b7391a9baca34259c6b90.png"),
    amazon: require("../assets/images/tech/f352750decdc5fe111e8ef65fee30d67.png"),
    ehhreum: require("../assets/images/tech/fa658903420c202b34845c470b94e62c.png"),
    illustratoe: require("../assets/images/tech/faa5c5c91016b73ea9183f681ca7ac83.png"),
    photoshop: require("../assets/images/tech/image 8photoshop.png"),
    figma: require("../assets/images/tech/Figma-Logo@2x-1024x529 1.png"),
    inVision: require("../assets/images/tech/image 84invision.png"),
    sketch: require("../assets/images/tech/image 85sketch.png"),
    balsamiq: require("../assets/images/tech/image 86balsamiq.png"),
    uxpin: require("../assets/images/tech/image 88uxpin.png"),
    zeplin: require("../assets/images/tech/image 89zeplin.png"),
    origami: require("../assets/images/tech/origami-logo-9 1.png"),
    socket: require("../assets/images/tech/socket.jpg"),
  },
};

export default images;
