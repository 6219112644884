import { Link, useNavigate } from "react-router-dom";
import images from "../../helpers/uploads";
import { FaAngleDown, FaWindowClose } from "react-icons/fa";
import Dropdown, { DropdownItem, DropDownMenu } from "./Dropdown";

export default function Header() {
  // const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();

  // HANDLERS
  // const toggleSidebar = () => {
  //   setSidebar(!sidebar);
  // };
  const sideOpentoggleClassFn = () => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.remove("close");
    document.getElementsByTagName("html")[0].classList.add("disableScroll");
  };
  const sideClosetoggleClassFn = () => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.add("close");
    document.getElementsByTagName("html")[0].classList.remove("disableScroll");
  };
  const openSidebarsubMenufn = (e) => {
    let arrowParent =
      e.target.tagName === "path"
        ? e.target.parentElement.parentElement.parentElement
        : e.target.parentElement.parentElement;

    arrowParent.classList.toggle("showMenu");
  };

  
  return (
    <>
      <header className="header">
        <div className="container-fluid ">
          <div className="row-wrap ">
            <div className="logo-wrap">
              <span className="logo">
                <Link to="/">
                  <img src={images.logo} alt="loasdgo" />
                </Link>
              </span>
            </div>
            <div className={`nav-wrap`}>
              <ul id="header-ul" className="nav-list">
                <li>
                  <Dropdown title="Technologies">
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.blockchain}
                        name="Blockchain"
                        link="/blockchain"
                      />
                      <DropdownItem
                        image={images.header.iot}
                        name="Internet of Things"
                        link="/iot"
                      />
                      <DropdownItem
                        image={images.header.vr}
                        name="Virtual/Augmented Reality"
                        link="/vr"
                      />
                      { <DropdownItem
                        image={images.header.quantum}
                        name="Qunatum Computing"
                        link="/quantum"
                      /> }
                    </DropDownMenu>
                     <DropDownMenu>
                      <DropdownItem
                        image={images.header.ml}
                        name="Machine Learning and AI"
                        link="/ml"
                      />
                      <DropdownItem
                        image={images.header.penetration}
                        name="Penetration Testing"
                        link="/penetration-testing"
                      />
                    </DropDownMenu> 
                  </Dropdown>
                </li>
                <li>
                  <Dropdown title="Engineering">
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.fs}
                        name="Fullstack Development"
                        link="/fullstack"
                      />

                      <DropdownItem
                        image={images.header.md}
                        name="Mobile Development"
                        link="/mobile"
                      />
                      <DropdownItem
                        image={images.header.uiux}
                        name="UI/UX Design"
                        link="/uiux"
                      />
                    </DropDownMenu>
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.se}
                        name="System Engineering"
                        link="/system"
                      />
                      <DropdownItem
                        image={images.header.doe}
                        name="DevOps Engineering"
                        link="/devops"
                      />
                      <DropdownItem
                        image={images.header.cloud}
                        name="Cloud Transformation"
                        link="/consulting"
                      />
                    </DropDownMenu>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown title="Services">
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.pd}
                        name="Product Design"
                        link="/productdesign"
                      />
                      <DropdownItem
                        image={images.header.consult}
                        name="Consulting"
                        link="/consulting"
                      />
                    </DropDownMenu>
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.fcd}
                        name="Full-Cycle Development"
                        link="/fullcycledev"
                      />
                      <DropdownItem
                        image={images.header.mas}
                        name="Maintenance and Support"
                        link="/mainteance"
                      />
                    </DropDownMenu>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown title="Resources">
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.article}
                        name="Articles"
                        link="/ml"
                        disabled={true}
                      />
                      <DropdownItem
                        image={images.header.cs}
                        name="Contact Support"
                        link="/contact"
                      />
                    </DropDownMenu>
                    <DropDownMenu>
                      <DropdownItem
                        image={images.header.faq}
                        name="FAQ"
                        link="/faq"
                      />
                    </DropDownMenu>
                  </Dropdown>
                </li>
                <li>
                  <Link to="/ip">IP Protection</Link>
                </li>
                <li>
                  <button
                    className="theme_button"
                    onClick={() => navigate("/contact")}
                  >
                    Contact Us
                  </button>
                </li>
              </ul>
            </div>
            <div className="sidebar close">
              <FaWindowClose
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={sideClosetoggleClassFn}
              />
              <ul className="nav-links">
                <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className="bx bx-collection"></i>
                      <span className="link_name">Category</span>
                    </Link>
                    <FaAngleDown
                      className="sidebar-dropdown-arrow"
                      onClick={openSidebarsubMenufn}
                    />
                  </div>
                  <ul className="sub-menu">
                    {[
                      {
                        image: images.header.blockchain,
                        link: "/blockchain",
                        name: "Blockchain",
                      },
                      {
                        image: images.header.iot,
                        link: "/iot",
                        name: "Internet of Things",
                      },

                      {
                        image: images.header.cloud,
                        link: "/custom",
                        name: "Cloud Transformation",
                      },

                      {
                        image: images.header.penetration,
                        link: "/interm",
                        name: "Penetration Testing",
                      },
                      {
                        image: images.header.ml,
                        link: "/ml",
                        name: "Machine Learning and AI",
                      },
                      {
                        image: images.header.vr,
                        link: "/vr",
                        name: "Virtual/Augmented Reality",
                      },
                    ].map((el) => {
                      return (
                        <li onClick={sideClosetoggleClassFn}>
                          <Link to={el.link}>
                            <img
                              width="30"
                              height="30"
                              src={el.image}
                              alt="asd"
                            ></img>{" "}
                            &nbsp;
                            <span>{el.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className="bx bx-collection"></i>
                      <span className="link_name">Engineering</span>
                    </Link>
                    <FaAngleDown
                      className="sidebar-dropdown-arrow"
                      onClick={openSidebarsubMenufn}
                    />
                  </div>
                  <ul className="sub-menu">
                    {[
                      {
                        image: images.header.blockchain,
                        name: "Fullstack Development",
                        link: "/fullstack",
                      },

                      {
                        image: images.header.cloud,
                        name: "Mobile Development",
                        link: "/mobile",
                      },

                      {
                        image: images.header.penetration,
                        name: "DevOps Engineering",
                        link: "/devops",
                      },
                      {
                        image: images.header.iot,
                        name: "System Engineering",
                        link: "/system",
                      },
                      {
                        image: images.header.ml,
                        name: "UI/UX Design",
                        link: "/uiux",
                      },
                    ].map((el) => {
                      return (
                        <li onClick={sideClosetoggleClassFn}>
                          <Link to={el.link}>
                            <img
                              width="30"
                              height="30"
                              src={el.image}
                              alt="asd"
                            ></img>{" "}
                            &nbsp;
                            <span>{el.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className="bx bx-collection"></i>
                      <span className="link_name">Services</span>
                    </Link>
                    <FaAngleDown
                      className="sidebar-dropdown-arrow"
                      onClick={openSidebarsubMenufn}
                    />
                  </div>
                  <ul className="sub-menu">
                    {[
                      {
                        image: images.header.blockchain,
                        name: "Product Design",
                        link: "/productdesign",
                      },
                      {
                        image: images.header.iot,
                        name: "Full-Cycle Development",
                        link: "/fullcycledev",
                      },
                    ].map((el) => {
                      return (
                        <li onClick={sideClosetoggleClassFn}>
                          <Link to={el.link}>
                            <img
                              width="30"
                              height="30"
                              src={el.image}
                              alt="asd"
                            ></img>{" "}
                            &nbsp;
                            <span>{el.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className="bx bx-collection"></i>
                      <span className="link_name">Resources</span>
                    </Link>
                    <FaAngleDown
                      className="sidebar-dropdown-arrow"
                      onClick={openSidebarsubMenufn}
                    />
                  </div>
                  <ul className="sub-menu">
                    {[
                      {
                        image: images.header.blockchain,
                        name: "Articles",
                        link: "/ml",
                      },
                      {
                        image: images.header.iot,
                        name: "Contact Support",
                        link: "/contact",
                      },
                      {
                        image: images.header.penetration,
                        name: "FAQ",
                        link: "/faq",
                      },
                    ].map((el) => {
                      return (
                        <li onClick={sideClosetoggleClassFn}>
                          <Link to={el.link}>
                            <img
                              width="30"
                              height="30"
                              src={el.image}
                              alt="asd"
                            ></img>{" "}
                            &nbsp;
                            <span>{el.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <div className="iocn-link" onClick={sideClosetoggleClassFn}>
                    <Link to="#">
                      <i className="bx bx-collection"></i>
                      <span className="link_name">IP Protection</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={`hamburger`} onClick={sideOpentoggleClassFn}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </div>
      </header>
      <div className="overlay"></div>
    </>
  );
}
