import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Dropdown({ title, children }) {
  const [show, setShow] = useState(false);
  return (
    <div
      className="custom-dropdown-container"
      // onMouseLeave={() => setShow(false)}
    >
      <Link to="#" onClick={() => setShow(!show)}>
        {title} <FaChevronDown />
      </Link>
      <div className={"custom-dropdown"}>{children}</div>
    </div>
  );
}

export function DropDownMenu({ children }) {
  return <ul className="custom-dropdown-menu">{children}</ul>;
}

export function DropdownItem({ image, name, link, disabled }) {
  return (
    <Link
      to={link}
      disabled={disabled}
      className={`${disabled ? "disabledHead" : ""}`}
    >
      <li className="custom-dropdown-item">
        <div
          className="p-1 mx-3"
          style={{
            boxShadow: " 0rem 0rem .6rem rgba(0, 0, 0, 0.2)",
            borderRadius: ".8rem",
          }}
        >
          <img className="custom-dropdown-item-img" src={image} alt={name} />
        </div>
        <span>{name}</span>
      </li>
    </Link>
  );
}
