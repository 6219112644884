import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import SIDECARD from "../../components/side_card";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function IP() {
  return (
    <div className="container-fluid">
        <span>
          <p className="fs-4">
          Welcome to Devstack Soft Solutions Inc. This Privacy Policy describes how we collect, use, disclose, and protect your personal information when you interact with our website and services. We are committed to safeguarding your privacy and ensuring that your personal information is handled securely and responsibly. By using our website and services, you consent to the practices described in this policy.

Information We Collect
<p>
1.1 Personal Information:
We may collect personal information that you provide to us voluntarily when you interact with us, including but not limited to:

Contact information (such as name, email address, phone number, and mailing address)
Billing and payment information
Information you provide when you communicate with us through email, phone, or other channels
</p>

<p>
1.2 Non-Personal Information:
We may also collect non-personal information, which does not directly identify you. This information may include:

Internet Protocol (IP) address
Browser type and version
Operating system
Date and time of your visit
Referring/exit pages
How We Use Your Information

</p>
<p>
2.1 Personal Information:
We may use your personal information for the following purposes:

To communicate with you and provide the services you request
To process payments and manage billing
To respond to your inquiries and provide support
To send you promotional materials and newsletters (only if you have opted in)
To improve our website and services
To comply with legal obligations
</p>

<p>
2.2 Non-Personal Information:
We use non-personal information to analyze trends, administer our website, track users' movements, and gather demographic information for aggregate use.
</p>

<p>
Information Sharing and Disclosure
<p>
3.1 Third-Party Service Providers:
We may share your personal information with third-party service providers who assist us in operating our business, such as payment processors, IT service providers, and marketing service providers. These third parties are contractually obligated to handle your personal information securely and confidentially.
</p>
<p>
3.2 Legal Requirements:
We may disclose your personal information if required to do so by law or in response to valid legal requests, such as a court order, government investigation, or to protect our rights, property, or safety.
</p>
<p>
3.3 Business Transfers:
In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity. We will notify you via email and/or a prominent notice on our website of any change in ownership or use of your personal information.
</p>
</p>

<p>
4. Security Measures
We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. We implement physical, technical, and administrative security measures to maintain the confidentiality and integrity of your personal information.
</p>

<p>
Your Rights and Choices
5.1 Access and Update:
You have the right to access, correct, update, or delete your personal information. If you would like to exercise any of these rights, please contact us using the information provided at the end of this Privacy Policy.

5.2 Marketing Communications:
If you no longer wish to receive promotional materials or newsletters from us, you can opt-out by following the instructions included in the communication or by contacting us.

</p>
6. Third-Party Links
Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. We encourage you to review the privacy policies of those third parties before providing them with your personal information.
</p>

<p>
Children's Privacy
Our services are not directed to individuals under the age of 16, and we do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to delete such information promptly.
          </p>
        </span>      
        </div>
      
  );
}
