import { Tab } from "bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Card, Col, Row, Tabs } from "react-bootstrap";
import images from "../../helpers/uploads";
import Title from "../../components/title";
import DETAIL_CARD from "../../components/detail_card";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import mainVideo from "../../assets/videos/main.mp4"
import Helmet from "helmet";

const Animate = (props) => {
  return (
    <AnimationOnScroll
      animateIn="animate__fadeInUp"
      animateOut="animate__fadeOutDown"
      className="animationWrapper"
      delay={0}
      initiallyVisible={false}
    >
      {props.children}
    </AnimationOnScroll>
  );
};

export default function Home() {
  return (
    <div className="container-fluid">
    <Helmet>
            <title>Home</title>
            <meta name="description" content="Blockchain technology is the hottest new trend in the tech world. It's a disruptive, secure, transparent and decentralized ledger for transactions. We provide blockchain consulting in Toronto to help you analyze your current process and determine if blockchain can improve it." />
            <meta name='Keywords' content='blockchain consulting in toronto, blockchain technology services'/>
          
        </Helmet>
      <div className="background mainScreenBackground">
        <video autoPlay muted loop>
          <source
            src={mainVideo}
            type="video/mp4"
          />
        </video>
        <div className="col-md-4 col-sm-12 content">
          <h1 className="fw-light"> Product Built with Precision</h1>
          <h2 className="mt-3 fw-light mb-5">
            Scalable, secure and futuristic.
          </h2>
          <Link to="/contact" className="yellow_btn_lg">
            Contact Us
          </Link>
        </div>
      </div>

      <div className="timeline mt-5">
        <div className="timeline__content i1">
          <div className="animationWrapper">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">We protect your concept</h2>
              </div>
              <p className="fs-4">
              To protect your intellectual property and prevent any compromise with the proprietorship of your idea, we begin every project by signing an NDA.
              </p>
            </>
          </div>
        </div>

        <div className="timeline__content i2" data-number="02">
          <div className="animationWrapper">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Comprehensive Consultation</h2>
              </div>
              <p className="fs-4">
              Participate with us in your adventure. Trust us with your concept, your business needs, and participate in thoughtful conversation with us.
              </p>
            </>
          </div>
        </div>

        <div className="timeline__content i6" data-number="03">
          <Animate key="03">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Design, analysis, and a prototype</h2>
              </div>
              <p className="fs-4">
              We never begin first without conducting an in-depth market analysis and developing sublime strategies in order to develop a prototype of your project.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i4" data-number="04">
          <Animate key="04">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">
                User Interface (UI) and User Experience (UX) design (UX)               
               </h2>
              </div>
              <p className="fs-4">
              We create strikingly beautiful and responsive applications and websites that capture high user engagement in response to your future needs.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i5" data-number="05">
          <Animate key="05">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Agile Rapid Development</h2>
              </div>
              <p className="fs-4">
              We are able to deliver highly functional apps and websites in a short period of time cheers to your participation and our experience.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i6" data-number="06">
          <Animate key="06">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Quality Control and Testing</h2>
              </div>
              <p className="fs-4">
              No app or website is ever released without being fully tested and improved in order to provide you with only the best of the best.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i7" data-number="07">
          <Animate key="07">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Deployment and Launch</h2>
              </div>
              <p className="fs-4">
              We launch your apps and websites seamlessly by integrating advanced technology to provide you with an unparalleled experience of high-rated technology.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i8" data-number="08">
          <Animate key="08">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Support and maintenance</h2>
              </div>
              <p className="fs-4">           
              We collaborate with you throughout the app cycle, welcome problems and issues, and deploy an expert team to help you in maintaining app experience.
              </p>
            </>
          </Animate>
        </div>

        <div className="timeline__content i9" data-number="09">
          <Animate key="09">
            <>
              <div className="myDivWrapper">
                <h2 className="content_p">Growth Hacking</h2>
              </div>
              <p className="fs-4">
                We share your load in whichever manner is possible, our
                outstanding SEO and market solutions will help you in converging
                your target market easily.
              </p>
            </>
          </Animate>
        </div>
      </div>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 row mt-5">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
            <DETAIL_CARD
              minHeight="35rem"
              image={images.misc.home[0]}
              title="Quality"
              text="We Work With Certain Ethics, And Our Development Process Reflects That; We Bring You Unparalleled Quality Stuff As Well As Highly Functional Websites And Applications"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
            <DETAIL_CARD
              minHeight="35rem"
              image={images.misc.home[1]}
              title="Speed"
              text="We Understand How Important Time Is For Our Clients, And We Base Our Speed On It. Don't Wait For Your Web Projects And Web Products Because We Deliver On Time"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
            <DETAIL_CARD
              minHeight="35rem"
              image={images.misc.home[2]}
              title="Flexibility"
              text="Customize, Mold, and Personalize Us - In Other Words, Use Our Services Under Your Terms Of Service. Take charge of your projects now, and guide us with your wonderful insights as needed"/>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
            <DETAIL_CARD
              minHeight="35rem"
              image={images.misc.home[3]}
              title="Engagement"
              text="We Work With Certain Ethics, And Our Development Process Needs to reflect That; We Bring You Unparalleled Quality Stuff As Well As Highly Functional Websites And Applications"
              />
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="px-5 mt-5">
          <Title heading="A Comprehensive Quality Approach" paragraph="" />
          <Tabs
            defaultActiveKey="Planning for Quality"
            transition={true}
            className="my-3 comprehensive-approach"
          >
            <Tab eventKey="Planning" title="Planning">
              <div className="col-12 row">
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-start">
                  <h2>Quality Planning</h2>
                  <h4 style={{ paddingRight: "4rem", width: "60%" }}>
                  Devstack focuses on implementing advanced quality tools that are supported by flexible standards and procedures. We make certain that the right elements are infused into the project development lifecycle to get the most out of it.
                  </h4>
                </div>
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[8]}
                    alt="BLIP"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Assurance" title="Assurance">
              <div className="col-12 row">
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-start">
                  <h2>Quality Assurance</h2>
                  <h4 style={{ paddingRight: "4rem", width: "60%" }}>
                  We establish stringent measures for evaluating the performance of your projects and ensure that all quality measures are implemented in the manner desired by the user.
                  </h4>
                </div>
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[6]}
                    alt="BLIP"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Control" title="Control">
              <div className="col-12 row">
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-start">
                  <h2>Quality Control</h2>
                  <h4 style={{ paddingRight: "4rem", width: "60%" }}>
                  We comply with exceptional performance standards that are based on quality and excellence in order to test the code and monitor its consistency.
                  </h4>
                </div>
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[7]}
                    alt="BLIP"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 row mt-5 px-5">
          <Title
            heading="Devstack Quality Assurance Department"
            paragraph="We have reserved an extraordinary division that is truly dedicated to processing the testing correctly. Our team of astute and skilled engineers works tirelessly on the assigned projects in order to achieve the best results possible. Depending on the complexity of the project, we deploy a team that can be shifted to another if necessary:"
          />
          <div className="col-12 row mt-5 d-flex flex-row-reverse flex-wrap">
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img src={images.misc.im[3]} alt="BLIp" className="img-fluid" />
              <h2 className="mt-3">
              Wide - ranging Quality Assurance Testing</h2>
            </div>
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img src={images.misc.im[5]} alt="BLIp" className="img-fluid" />
              <h2 className="mt-3">Documentation and code review</h2>
            </div>
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img src={images.misc.im[4]} alt="BLIp" className="img-fluid" />
              <h2 className="mt-3">Monitoring, tracking, and correction of flaws</h2>
            </div>
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img
                src={images.misc.small[0]}
                alt="BLIp"
                className="img-fluid"
              />
              <h2 className="mt-3">Management and configuration updates</h2>
            </div>
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img src={images.misc.im[1]} alt="BLIp" className="img-fluid" />
              <h2 className="mt-3">Management of processes and progress</h2>
            </div>
            <div
              style={{ minHeight: "20rem", justifyContent: "center" }}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex flex-column justify-content-center align-items-center text-center mt-"
            >
              <img src={images.misc.im[2]} alt="BLIp" className="img-fluid" />
              <h2 className="mt-3">Risk management</h2>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 mt-5 px-5">
          <Title heading="Quality Assurance Standards That Shaped Development And Quality" />
          <div className="col-12 row mt-3">
            <div
              className="col-md-3 col-sm-6 col-12 "
              style={{ marginTop: "7rem" }}
            >
              <div
                style={{ minHeight: "20rem", justifyContent: "center" }}
                className="card triagle_card"
              >
                <div className="card-body">
                  <div className="wrapper">
                    <img
                      src={images.misc.number[0]}
                      alt="BLIp"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content_wrapper mt-3">
                    <h3>Analysis of requirements and planning</h3>
                    <h5> We specify the project test cases as well as their development. Teams are formed as needed.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6 col-12 "
              style={{ marginTop: "7rem" }}
            >
              <div
                style={{ minHeight: "20rem", justifyContent: "center" }}
                className="card triagle_card"
              >
                <div className="card-body">
                  <div className="wrapper">
                    <img
                      src={images.misc.number[1]}
                      alt="BLIp"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content_wrapper mt-3">
                    <h3>Code Review</h3>
                    <h5>At the time of delivery, extensive testing is carried out in accordance with the test cases and plans.
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-sm-6 col-12 "
              style={{ marginTop: "7rem" }}
            >
              <div
                style={{ minHeight: "20rem", justifyContent: "center" }}
                className="card triagle_card"
              >
                <div className="card-body">
                  <div className="wrapper">
                    <img
                      src={images.misc.number[2]}
                      alt="BLIp"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content_wrapper mt-3">
                    <h3>Immediate testing</h3>
                    <h5>For newly built products, we ensure that QA tests are performed in order to meet the anticipated challenges.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6 col-12 "
              style={{ marginTop: "7rem" }}
            >
              <div
                style={{ minHeight: "20rem", justifyContent: "center" }}
                className="card  triagle_card"
              >
                <div className="card-body">
                  <div className="wrapper">
                    <img
                      src={images.misc.number[3]}
                      alt="BLIp"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content_wrapper mt-3">
                    <h3>Final verification</h3>
                    <h5>
                      Testing is given a final shape and accordingly is
                      processed for deployment.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="px-5 mt-5">
          <Title heading="Why we recommend Devstack?" paragraph="" />
          <Tabs
            defaultActiveKey="Project Based Pricing"
            transition={true}
            className="mb-3 recommended-devstack"
            id="controlled-tab-example"
          >
            <Tab
              className="recommended-devstack-tab-content"
              eventKey="Project Based Pricing"
              title="Project Based Pricing"
            >
              <div className="col-12 row">
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[5]}
                    alt="BLIP"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-end">
                  <h2>Project Pricing vs. Fixed Bid</h2>
                  <h4 style={{ paddingLeft: "4rem", width: "60%" }}>
                  Where there is great clarity in the development requirement, this is something for you; here you can choose between different models, with a pre-determined number of hours a developer will devote to your project, time-limit, and development cost.
                  </h4>
                </div>
              </div>
            </Tab>
            <Tab
              className="recommended-devstack-tab-content"
              eventKey="Dedicated Development"
              title="Retainer or Dedicated Development Facility"
            >
              <div className="col-12 row">
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[0]}
                    alt="BLIP"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-end">
                  <h2>Retainer or Dedicated Development Facility</h2>
                  <h4 style={{ paddingLeft: "4rem", width: "60%" }}>
                  We have a talented pool of industry experts; when you use Devstack, you will have access to a vast pool of expert developers and creative designers. Furthermore, it will be advantageous to hire our dedicated developers on a full-time, hourly, or part-time basis. Our developers are dedicated, hardworking IT professionals who understand the needs of your domain.
                  </h4>
                </div>
              </div>
            </Tab>
            <Tab
              className="recommended-devstack-tab-content"
              eventKey="Milestone-based"
              title="Milestone-based"
            >
              <div className="col-12 row">
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[2]}
                    alt="BLIP"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-end">
                  <h2>Milestone-based Billing</h2>
                  <h4 style={{ paddingLeft: "4rem", width: "60%" }}>
                  The bill is calculated by taking into account the number of hours spent by the resources on your project as well as the services you hired us for. We have a very flexible billing plan that allows you to pay us as each stage is completed.
                  </h4>
                </div>
              </div>
            </Tab>
            <Tab
              className="recommended-devstack-tab-content"
              eventKey="Effort Based Pricing"
              title="Effort Based Pricing"
            >
              <div className="col-12 row">
                <div className="col-md-6 col-12">
                  <img
                    src={images.chu[1]}
                    alt="BLIP"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-end">
                  <h2>Effort Based Pricing or Time & Material</h2>
                  <h4 style={{ paddingRight: "4rem", width: "60%" }}>
                  We understand how difficult it is to estimate certain things at the start of development because we see enormous potential in your idea. As a result, we assist you with daily development reporting, including developing and implementing a full-proof strategy, hiring development experts, and resource utilization to make your life easier.
                  </h4>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <div className="col-12 px-md-5 mt-5">
          <Title heading="Why Do We Suggest Staff Augmentation?" paragraph="" />
          <Row className={"px-5 mt-5 align-items-center"}>
            <Col md={6} sm={12}>
              <p style={{ width: "75%" }}>
              We recommend it for your enterprise benefits based on all of the staff augmentation benefits. It enables you to successfully evaluate your enterprise potential by analyzing your employees and resources, allowing you to work on additional skills and training while also gaining benefits.
              </p>
              <p style={{ width: "75%" }}>
              You would not voluntarily participate in the process of staffing, hiring, providing employment benefits, and taxes, for example, if you are a new or small business. Keeping all of this in mind, we at Dev Technosys recommend staff augmentation so that you can not only solve the problems but also be efficient enough to attend to your technical and business needs.
              </p>
              <Link to="/consulting" className="">
                <button className="yellow_btn_lg btn-block">
                  Get Free Consultation Now
                </button>
              </Link>
            </Col>
            <Col sm={12} md={6}>
              <Row className="d-flex flex-row align-items-stretch">
                <Col md={6} sm={12}>
                  <Card
                    style={{ minHeight: "20rem", justifyContent: "center" }}
                    className="smallCard"
                  >
                    <Card.Body className="row justify-content-center">
                      <div className="img_container col-6">
                        <img
                          src={images.misc.small[0]}
                          className="img-fluid"
                          alt="BLIP"
                        />
                      </div>
                      <div
                        className="content col-12 mt-4 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h3>Cost Effectiveness</h3>
                        <p
                          style={{ textAlign: "justify" }}
                          className="fs-5 px-2"
                        >
                          Not to mention the other benefits, this alone is a compelling reason to switch to staff augmentation; if you are a start-up or a SME, you will be able to save a fortune.
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card
                    style={{ minHeight: "20rem", justifyContent: "center" }}
                    className="smallCard"
                  >
                    <Card.Body className="row justify-content-center">
                      <div className="img_container col-6">
                        <img
                          src={images.misc.small[1]}
                          className="img-fluid"
                          alt="BLIP"
                        />
                      </div>
                      <div
                        className="content col-12 mt-4 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h3>Direct Access</h3>
                        <p
                          style={{ textAlign: "justify" }}
                          className="fs-5 px-2"
                        >
                          You will have complete freedom to participate in the development process from start to finish. We always welcome client suggestions to improve the product's impact.
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card
                    style={{ minHeight: "20rem", justifyContent: "center" }}
                    className="smallCard"
                  >
                    <Card.Body className="row justify-content-center">
                      <div className="img_container col-6">
                        <img
                          src={images.misc.small[2]}
                          className="img-fluid"
                          alt="BLIP"
                        />
                      </div>
                      <div
                        className="content col-12 mt-4 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h3>Time Efficient</h3>
                        <p
                          style={{ textAlign: "justify" }}
                          className="fs-5 px-2"
                        >
                          Not to mention the other benefits, this alone is a compelling reason to switch to staff augmentation; if you are a start-up or a SME, you will be able to save a fortune.

                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card
                    style={{ minHeight: "20rem", justifyContent: "center" }}
                    className="smallCard"
                  >
                    <Card.Body className="row justify-content-center">
                      <div className="img_container col-6">
                        <img
                          src={images.misc.small[3]}
                          className="img-fluid"
                          alt="BLIP"
                        />
                      </div>
                      <div
                        className="content col-12 mt-4 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h3>IP Protection</h3>
                        <p
                          style={{ textAlign: "justify" }}
                          className="fs-5 px-2"
                        >
                         Complete protection and confidentiality are maintained, and you retain ownership of the projects' intellectual property and copyrights. We strictly adhere to our work ethics.
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn my-5">
        <div className="col-12 px-5 mt-5">
          <Title
            heading="Think Unique? Then Why Settle For Generic?"
            paragraph=""
          />

          <div
            className="card p-3"
            style={{ boxShadow: "0rem 0rem 5px black" }}
          >
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-2 col-sm-6">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img
                      src={images.misc.round[0]}
                      alt="BLIP"
                      className="img-fluid"
                    />
                    <h3 className="mt-3">Cost-Effective Pricing</h3>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img
                      src={images.misc.round[1]}
                      alt="BLIP"
                      className="img-fluid"
                    />
                    <h3 className="mt-3">Flexible Billing Plans</h3>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img
                      src={images.misc.round[2]}
                      alt="BLIP"
                      className="img-fluid"
                    />
                    <h3 className="mt-3">Dedicated Development</h3>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img
                      src={images.misc.round[3]}
                      alt="BLIP"
                      className="img-fluid"
                    />
                    <h3 className="mt-3">
                      Build-Operate Transfer (BOT) Model{" "}
                    </h3>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img
                      src={images.misc.round[4]}
                      alt="BLIP"
                      className="img-fluid"
                    />
                    <h3 className="mt-3">Project Based Pricing or Fixed Bid</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
