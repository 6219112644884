export default function ROUND_CARD_2(props) {
  return (
    <>
      {/* <div className="col-12 col-sm-6 col-md-4 mt-5"> */}
      <div
        className="card round_card_3 "
      >
        <div className="col-12  text-center card_content">
          <img src={props.image} style={{ width: "64px" }}></img>
          <h2 className="fs-3 mt-2 mb-1" style={{ textTransform: "capitalize" }}>
            {props.title}
          </h2>
          <p className="fs-5" style={{lineHeight:"1.05rem"}}>{props.text}</p>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
