import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import TECH_CARD from "../../components/tech_cards/index";
import Timeline from "../../components/timeline";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function DEVOPS() {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Devops</title>
          <meta name="description" content="We are a Toronto based devops consulting company that helps companies to improve their software development and deployment process. We help organizations establish best practices for Continuous Integration and Continuous Delivery, enabling them to release high-quality software more frequently." />
          <meta name='Keywords' content='devops consulting companies in toronto'/>
        </Helmet>
      <Banner image={images.banner.devops}>
        <h1 className="fs-1 yellow-text mb-4">DevOps</h1>
        <h3 className="fs-4">
          <span className="yellow-text"> DevOps</span> is a collaborative way of
          developing and deploying software. It is a booming trend that brings
          business, development and operation teams together to accelerate
          service delivery and streamline IT by applying more automated
          processes. We have helped our clients deliver faster and have greater
          business agility by implementing DevOps Services.
        </h3>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br /> <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Our DevOps as a Service" />
        <br />
        <br />

        <Timeline
          timeline={[
            {
              title: "Infrastucture management",
              text: "Build an infrastucture that can adapt and update with future innovation for better services.",
            },
            {
              title: "Implementation and managed services",
              text: "Choose the right market and deploy the application successfully for profitable revenue generation",
            },
            {
              title: "Maintenance services",
              text: "Ensure that the application functions smoothly without any glitch and make sure it is easy to understand",
            },
            {
              title: "Scaling and migration",
              text: "Look for opportunities and train developers for devops to provide a competitive advantage",
            },
            {
              title: "Environment management",
              text: "Manage environmental coding variables efficiently to ensure that the application learns from its surroundings",
            },
            {
              title: "Configuration management",
              text: "Create and configure features with customized preferences to provide better user/customer experience",
            },
            {
              title: "Code integration and inspection",
              text: "Integrate different tools and codes to test the application efficiently and squash any bug that might hinder the performance.",
            },
            {
              title: "Technology research and innovation",
              text: "Constantly innovate with advanced technology tools to create attractive UI, speed up the development process.",
            },
          ]}
        />
      </AnimationOnScroll>
      <br />
      <br /> 
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Why should you Choose Devstack for DevOps as a Service?" />
        <div className="col-12 p-5">
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4">
              <div style={{ minHeight: "25rem" }} className="card tick_card">
                <div className="card-body">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img src={images.service.tick} alt="devops" />
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center text-center mt-4">
                    <h3>
                      Accelerated service delivery to boost competitive edge and
                      make you stay stay ahead of your competition effectively.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div style={{ minHeight: "25rem" }} className="card tick_card">
                <div className="card-body">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img src={images.service.tick} alt="devops" />
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center text-center mt-4">
                    <h3>
                      Customer centric development for better customer
                      satisfaction and feedback from customers for better
                      service.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div style={{ minHeight: "25rem" }} className="card tick_card">
                <div className="card-body">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img src={images.service.tick} alt="devops" />
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center text-center mt-4">
                    <h3>
                      Product delivery ahead of schedule and ensure that it
                      reaches customers at the right time and meet requirements.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div style={{ minHeight: "25rem" }} className="card tick_card">
                <div className="card-body">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img src={images.service.tick} alt="devops" />
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center text-center mt-4">
                    <h3>
                      Continuous integration and innovation to move towards an
                      enhanced digital market trend and evolution.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.amazon} />
            <TECH_CARD image={images.tech.prometheus} />
            <TECH_CARD image={images.tech.kuberNote} />
            <TECH_CARD image={images.tech.docker} />
            <TECH_CARD image={images.tech.jenkins} />
            <TECH_CARD image={images.tech.grafana} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
