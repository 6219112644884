import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function MachineLearning() {
  return (
    <div className="container-fluid">
          <Helmet>       
        <title>Machine Learning</title>
            <meta name="description" content="Machine Learning and tools help people make better decisions. We provide a platform for the development of the next generation of intelligent applications." />
            <meta name='Keywords' content='machine learning, data analytics, data science'/>
        </Helmet>
      <Banner image={images.banner.machineLearning}>
        <span>
        Machine Learning And AI Lab Empower Your Product With Our <span className="yellow-text">ML & AI</span>  Development Services.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Machine Learning and AI Lab" paragraph="" />

        <div className="col-12 p-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <img className="img-fluid" src={images.service.ml[0]} alt="IOT" />
            </div>
            <div className="col-12 col-md-6">
              <img className="img-fluid" src={images.service.ml[1]} alt="IOT" />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Empower your product with Our ML & AI Development Services"
          paragraph=""
        />
        <div className="col-12 p-5">
          <div className="row">
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[5]}
              heading={"Machine Learning"}
              paragraph="We Encapsulate ML Algorithms That Allow The Product To Make Decisions In Seconds And Exactly Leverage Efficiency And Automation Virtues."
            />
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[4]}
              heading={"Image Processing"}
              paragraph="We Concentrate On All Of Your Product's Image And Video Processing Fundamentals And Lay The Paradigms To Increase Efficacy."
            />
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[3]}
              heading={"Chatbot development"}
              paragraph="We create top-tier chatbot solutions that are human-like in their execution of all activities associated with user interaction or any other product functionalities."
            />
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[2]}
              heading={"Robotics processing"}
              paragraph="With our all-new robotic processing and development, we assist your product in incorporating automation while minimizing manpower."
            />
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[1]}
              heading={"Natural language processing"}
              paragraph="By using our profound Artificial Intelligence Development techniques, we ensure to engineer a highly powerful decision and analysis system that sets your product free from NLP."
            />
            <FIRMCARD
              minHeight={"25rem"}
              image={images.misc.ml[0]}
              heading={"Empowered Neural networks"}
              paragraph="By harnessing the power of our AI development strategies, we develop the astute neural and learning network that helps to interpret the tedious operations and executions."
            />
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.tensorflow} />
            <TECH_CARD image={images.tech.pytorch} />
            <TECH_CARD image={images.tech.keras} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
