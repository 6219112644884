import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import FIRMCARD_1 from "../../components/firmCard_1";
import ROUND_CARD_3 from "../../components/round_card_3";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Helmet from "helmet";

export default function CONTACT() {
  return (
    <div className="container-fluid">
         <Helmet>       
        <title>Full Cycle Development</title>
            <meta name="description" content="Find software development companies in Canada for outsourcing software projects, custom software development, mobile application development, and more." />
            <meta name='Keywords' content='software companies in canada toronto'/>
        </Helmet>
      <Banner image={images.banner.webDevelopment}>
        <h1 className="fs-1 yellow-text fw-light mb-4">
          Full-Cycle Development
        </h1>
        <h2 className="fs-3 fw-light">
          Wherever you are on your digital journey, our development services
          have an engagement model to support you.
        </h2>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Full Lifecycle Support" paragraph="" />

        <div
          className="col-12 row px-2 d-flex justify-content-center px-md-5"
          style={{ marginTop: "6rem" }}
        >
          <div className="col-12 col-sm-12 col-sm-6 col-md-5 px-5 mb-5 d-flex justify">
            <FIRMCARD_1
              minHeight="100%"
              image={images.misc.star}
              heading={"Readiness"}
              paragraph={
                "Engagements that help you with the transformation strategy and skills adoption needed to get the full value of your investments in modernizing legacy infrastructure and applications."
              }
            />
          </div>
          <div className="col-12 col-sm-12 col-sm-6 col-md-5 px-5 mb-5">
            <FIRMCARD_1
              minHeight="100%"
              image={images.misc.star}
              heading={"Deploy"}
              paragraph={
                "Our engineers tackle your application development milestones, cloud migrations and infrastructure deployment. Engagements can be sprint based engagements, or we can provide the technical resources needed for your own directed implementations."
              }
            />
          </div>
          <div className="col-12 col-sm-12 col-sm-6 col-md-5 px-5 mb-5">
            <FIRMCARD_1
              minHeight="100%"
              image={images.misc.star}
              heading={"Design"}
              paragraph={
                "We enable you to build secure,high-performing, resilient, and efficient infrastructure, modern applications and automated processes. An integral part of most projects, can be delivered stand-alone or coupled with Readiness or Deploy engagements to ensure a consistent approach."
              }
            />
          </div>
          <div className="col-12 col-sm-12 col-sm-6 col-md-5 px-5 mb-5">
            <FIRMCARD_1
              minHeight="100%"
              image={images.misc.star}
              heading={"Operate"}
              paragraph={
                "Our development services improve your existing platforms in the data center or in the cloud. We go deep on workload optimization, automation, cost optimization, and diagnostic tools to help tune and optimize for security and performance."
              }
            />
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="To get your project underway simply trust your idea to the team of experts"
          paragraph=""
        />
        <div
          className="full-cycle-dev-circle-wrapper col-12 row px-2 d-flex justify-content-center  px-md-5"
          style={{ marginTop: "6rem", flexWrap: "wrap" }}
        >
          {[
            {
              title: "Design",
              text: "We provide remediation recommendations based on environment discovery, and create application architecture designs and roadmaps specific to your business requirements.",
              image: images.misc.cycle[0],
            },
            {
              title: "Build",
              text: "Our engineers make your desired application end-state happen and can write and deliver custom applications at supersonic speed.",
              image: images.misc.cycle[0],
            },
            {
              title: "Manage",
              text: "We provide ongoing review, support and managed consistency services to proactively deliver management recommendations for your applications.",
              image: images.misc.cycle[2],
            },
          ].map((el, index) => (
            <ROUND_CARD_3 title={el.title} text={el.text} image={el.image} />
          ))}
        </div>
      </AnimationOnScroll>
    </div>
  );
}
