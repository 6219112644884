export default function ROUND_CARD_2(props) {
  return (
    <>
      {/* <div className="col-12 col-sm-6 col-md-4 mt-5"> */}
      <div
        className="card round_card_4 "
        style={{ minHeight: `${props.minHeight}` }}
      >
        <div className="col-12 text-center card_content">
          <img src={props.image} className="bg-light"></img>
          <h2
            className="round_card_4_title fs-3 mt-2 mb-1"
            style={{textTransform: "capitalize" }}
          >
            {props.title}
          </h2>
          <p className="fs-5" style={{lineHeight:"1.2rem"}}>{props.text}</p>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
