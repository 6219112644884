export default function ROUND_CARD_2(props) {
  return (
    <>
      {/* <div className="col-12 col-sm-6 col-md-4 mt-5"> */}
      <div
        className="card round_card_2 "
      >
        <div className="col-12  text-center card_content">
          <img src={props.image} style={{ width: "64px" }}></img>
          <h1
            className="fs-2 fw-light mt-3"
          >
            {props.title}
          </h1>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
