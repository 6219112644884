export default function FIRMCARD(props) {
  return (
    <div
      className="card firm_body_1"
      style={{ minHeight: `${props.minHeight}` }}
    >
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 d-flex justify-content-center align-items-center mb-4 ">
          <div className="image_container me-1">
            <img className="img-fluid" src={props.image} alt="FIRM" />
          </div>
          <h1 className="fs-1 ">{props.heading}</h1>
        </div>
        <div className="col-12 ">
          <div className="content_container">
            <p className="fs-4">{props.paragraph}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
