import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function INTERM() {
  return (
    <div className="container-fluid">
      <Banner image={images.banner.interm}>
        <span>
          <h1 className="fs-1 yellow-text mb-4">Interim CTO</h1>
          <h3 className="mt-3">
            You shouldn't need to be a technical specialist to concentrate on
            your product’s success. Our CTO service provides you with the
            knowledge and experience you need to establish a long-term IT
            practice.
          </h3>
          <p className="mt-5" style={{ fontSize: "1.4rem" }}>
            As an Interim CTO, we will function as your company's CTO in order
            to achieve specific goals. We engage our Interim CTO service to meet
            your specific needs so you can get back on track.
          </p>
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="What does an engagement look 
        like with our CTO?"
          paragraph="To assure engagement success, we follow a three-step procedure. It all starts with getting to know you and meeting you where you are."
        />
        <div className="col-12 px-5">
          <p className="fs-4">
            <b>Step 1.</b> The discovery phase of the interaction begins. The
            purpose of this phase is to have a better understanding of your
            company and its product. We put together an actionable plan to help
            steer you toward your goals after we understand where you are and
            where you want to go.
          </p>
          <p className="mt-5 fs-4">
            <b>Step 2.</b> Once a strategy has been established, we begin
            consulting and developing software; this is our bread and butter.
            Setting technical direction, mentoring teams, building teams,
            designing software, hiring, defining products, and more are all
            included.
          </p>
          <p className="mt-5 fs-4">
            <b>Step 3.</b> Once we've gotten you to where you want to be, we'll
            work with your internal team to transition the CTO role and
            responsibilities to them. As the contract comes to a close, your
            Interim CTO will be accessible to you as a consultant.
          </p>
          <h4 className="mt-5 fs-4">
            Read a more detailed description of each step below.
          </h4>
        </div>
      </AnimationOnScroll>
      <br /> <br /> <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Benefits of an Interim CTO"
          paragraph="We customize our workforce to meet your specific requirements. For a technical evaluation, a Devstack team will often use seasoned Lead and Principal consultants that specialize in product and engineering."
        />
        <div className="col-12 row px-5">
          <div className="yellow_list col-12">
            <ul>
              <li>Technical know-how, leadership, and vision</li>
              <li>
                Decades of technological expertise in a wide range of industry
                domains, team environments and stacks
              </li>
              <li>Customer focused delivery</li>
              <li>Product alignment and engineering</li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>
      <br /> <br /> <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Thorough Interim CTO Process" paragraph="" />
        <div className="col-12 row px-5">
          <h2 className="yellow-text">Assessment</h2>
          <div className="yellow_list col-12">
            <ul>
              <li>Team members skill set</li>
              <li>Task Distribution</li>
              <li>Product</li>
              <li>Desired Milestones</li>
              <li>Software development methodology</li>
              <li>Product management methodology</li>
              <li>Technical obstructions</li>
            </ul>
          </div>
          <h2 className="yellow-text mt-5">Consulting</h2>
          <div className="yellow_list col-12">
            <ul>
              <li>Establishing working relationships</li>
              <li>Setting up agile working environment</li>
              <li>
                Identifying gaps in the organization's and team's skill sets
              </li>
              <li>As needed, bringing on new members to the team</li>
              <li>Robust Quality Assurance on new functionality</li>
              <li>
                Assessing and solving the challenges by interacting with the
                team
              </li>
            </ul>
          </div>
          <h2 className="yellow-text mt-5">Transition</h2>
          <div className="yellow_list col-12">
            <ul>
              <li>Creating a request for a full-time CTO</li>
              <li>Interim CTO analyses and onboards the candidates</li>
              <li>The CTO observes carefully in the onboarding process</li>
              <li>
                Periodic follow ups after the CTo has left for certain time
                period
              </li>
              <li>Interim CTO as a resource, a partner, and a community</li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
