export default function FIRMCARD(props) {
  return (
    <div className="col-md-6 col-sm-12 mt-5">
      <div
        className="card firm_body"
        style={{ minHeight: `${props.minHeight}` }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-3 d-flex justify-content-center justify-content-sm-start mb-4 ">
              <div className="image_container">
                <img className="img-fluid" src={props.image} alt="FIRM" />
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="content_container">
                <span className="fs-3 mb-2">{props.heading}</span>
                <br />
                <br />
                <p className="fs-4">{props.paragraph}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
