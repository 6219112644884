import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import details from "../../components/footer/details";
import { send } from 'emailjs-com';
import emailDetails from "./email.js"


const getValue=(v)=>{
  return v?.value
}

const onSubmit = (e) => {
  e.preventDefault();
  let {name, companyName, email, inquiry, nda }=e.target
  let metadata=emailDetails.metadata
    metadata.message={
      name: getValue(name),
      companyName: getValue(companyName),
      email: getValue(email),
      inquiry: getValue(inquiry),
      nda:     Boolean(getValue(nda))?'Yes':'No'

    }
    send(emailDetails.service_id, emailDetails.template_id, metadata, emailDetails.user_id ).then((result) => {
      console.log('email sent successfully')
      e.target.reset()
  }, (error) => {
      console.log('error sending email')
  });



}

export default function CONTACT() {
  let det=details.CA
  return (
    <div className="container-fluid">
      <Banner image={images.banner.contact}>
        <h1 className="fs-1 yellow-text mb-4">Contact Sales</h1>
        <span>Let’s build great things together!</span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Contact us"
          paragraph="Fill in the form or contact us directly:"
        />

        <div className="col-12 p-5">
          <form className="row contactUsForm" onSubmit={onSubmit}>
            <div className="form-group col-md-6 mt-5">
              <input
                type="text"
                className="input_contact"
                aria-describedby="emailHelp"
                placeholder="Your Full Name*"
                name="name"
              />
            </div>
            <div className="form-group col-md-6 mt-5">
              <input
                type="text"
                className="input_contact"
                aria-describedby="emailHelp"
                placeholder="Your Company Name*"
                name="companyName"
              />
            </div>
            <div className="form-group col-md-6 mt-5">
              <input
                type="email"
                className="input_contact"
                aria-describedby="emailHelp"
                placeholder="Your e-mail*"
                name="email"
              />
            </div>
            <div className="form-group col-md-12 mt-5">
              <textarea
                className="input_contact"
                aria-describedby="emailHelp"
                placeholder="Your question*"
                name="inquiry"
              />
            </div>
            {/* <div className="form-group col-md-12 mt-5 d-flex align-items-center attachFiles">
              <img
                src={images.misc.attachment}
                style={{ width: "32px" }}
                alt="attachment"
              />
              <h3 className="mx-3 fs-3">Attach files</h3>
              <input type="file" name="attchedFiles" multiple />
            </div> */}
            <div className="form-group col-md-12 mt-5 d-flex align-items-center">
              <input type={"checkbox"} className="mx-2" />
              <label>
                I have read and accept the{" "}
                <span style={{ textDecoration: "underline" }}>
                  <Link to="/">Privacy Policy</Link>
                </span>
              </label>
            </div>
            <div className="form-group col-md-12 mt-5 d-flex align-items-center">
              <input type={"checkbox"}  name="nda" className="mx-2" />
              <label>Send me NDA</label>
            </div>
            <div className="col-md-12 d-flex justify-content-center mt-4">
              <button
                className="btn yellow_btn_lg px-5 py-3 text-dark"
                style={{ fontWeight: "bolder" }}
                type='submit'
              >
                SUBMIT REQUEST
              </button>
            </div>
          </form>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Explore your ideas with us!" paragraph="" />
        <div className="col-12 p-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h3 className="fs-1 fw-light">
                <b>Call us:</b>
              </h3>
              <br />
              <h4 className="fs-2">
                {
                  det.phone.map(phone=>{{
                    return (<div> 
                      <img src={images.misc[det.countryCode]} width="30"></img>&nbsp;
                    <b>{phone}</b>
                    <br />
                   </div>)
                  }})
                }
              </h4>
            </div>
            <div className="col-md-6">
              <h3 className="fs-1 fw-light">
                <b>Email us:</b>
              </h3>
              <br />
              <h4 className="fs-2">
                <img src={images.misc.envolap} width="30"></img>&nbsp;
                <b>{det.email}</b>
              </h4>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
