export default function DETAIL_CARD(props) {
  return (
    <>
      <div
        className="card service_card py-4"
        style={{ minHeight: `${props.minHeight}` }}
      >
        <div className="card-body px-5 py-3">
          <div className="row __consulting">
            <div className="col-12 d-flex justify-content-center image_container round">
              <img
                src={props.image}
                style={{ width: "64px", height: "64px" }}
                alt="service"
              />
            </div>
            <div className="my-4 col-12 d-flex flex-column justify-content-center align-items-center text-center card_content my-2">
              <h1 className="fs-2 service_card_title fw-light">{props.title}</h1>
              <h5
                className="service_card_desc mt-3 fs-4 fw-light"
                style={{
                  textTransform: "capitalize",
                  lineHeight: "2.5rem",
                }}
              >
                {props.text}.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
