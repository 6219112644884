export default function Timeline(props) {
  return (
    <>
      <div className="timeline">
        {props.timeline.map((item, index) => {
          return (
            <div className="timeline__content">
              <h2 className="content_p">{item.title}</h2>
              <p className="fs-4">{item.text}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
