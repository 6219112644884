export default function ROUND_CARD_1(props) {
  return (
    <>
      {/* <div className="col-12 col-sm-6 col-md-4 mt-5"> */}
      <div
        className="card round_card"
        style={{ minHeight: `${props.minHeight}` }}
      >
        <h2 className="round_card_count">{props.count + "."}</h2>
        <div className="card-body p-5 d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center text-center card_content">
              <h1
                className="fs-1 fw-light"
              >
                {props.title}
              </h1>
              <h5
                className="service_card_desc mt-3 fs-5"
                style={{
                  textTransform: "capitalize",
                  lineHeight: "1.5rem",
                }}
              >
                {props.text}
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
