// NAVIGATION
import Navigate from "../navigations/navigate";

// MAIN COMPONENTS
import Header from "../components/header";
import Footer from "../components/footer";

// STYLING
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/scss/global.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "animate.css/animate.min.css";

function App() {
  return (
    <>
      <Header />
      <Navigate />
      <Footer />
    </>
  );
}

export default App;
