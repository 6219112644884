import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import FIRMCARD_1 from "../../components/firmCard_1";
import ROUND_CARD_4 from "../../components/round_card_4";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Helmet from "helmet";

export default function CONTACT() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>maintainance</title>
            <meta name="description" content="Software Outsourcing Canada: Working with Canadian software outsourcing companies to outsource your development and IT projects. Get the expertise you need without the costly overhead." />
            <meta name='Keywords' content='blockchain consulting in toronto'/>
        </Helmet>
      <Banner image={images.banner.customerSupport}>
        <h1 className="fs-1 yellow-text fw-light">Maintenance and Support</h1>
        <h2 className="fw-light fs-3">
          We are there for you. 24/7 our mobile app experts will be available to
          handle your queries and squash bugs.
        </h2>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Our Software Maintenance and 
          Support Process"
          paragraph="Our process works like clockwork because we have a systematic framework in place to process your requirement from end-to-end. Our knowledge repositories on enterprise applications will make it simpler for you to grasp our transparent methodologies. We will also provide close-knit support if you prefer having an agent to walk you through the stages. Our process is as follows -"
        />
        <br />
        <br />
        <br />
        <div className="mainteance-circle-wrapper col-12 row px-2 d-flex justify-content-center  py-5">
          {[
            {
              title: "Requirement Collection",
              text: "We will acquire the documentation and processes followed by your developers. The details will help us determine maintenance objectives.",
              image: images.misc.maintain[4],
            },
            {
              title: "Program Analysis",
              text: "We will rely on knowledge sharing on enterprise applications to decode the program objectives. This will help us interpret the complexity and self-descriptiveness.",
              image: images.misc.maintain[0],
            },
            {
              title: "Maintenance Proposal",
              text: "We will arrive at the best maintenance strategy and the maintenance proposal will be furnished for your approval.",
              image: images.misc.maintain[2],
            },
            {
              title: "Evaluating Infrastructure Stability",
              text: "We will analyze the extendibility and stability of the infrastructure to understand the potential for the ripple effect.",
              image: images.misc.maintain[1],
            },
            {
              title: "Testing",
              text: "The maintenance protocol, once implemented, will be vigorously tested for ruggedness and reliability. If necessary, ongoing preventive maintenance efforts will be provided.",
              image: images.misc.maintain[5],
            },
            {
              title: "Release and Reporting",
              text: "Once approved for release, we will compile our response and actions in a legible report for recordkeeping and future reference.",
              image: images.misc.maintain[3],
            },
          ].map((el, index) => (
            <ROUND_CARD_4 title={el.title} text={el.text} image={el.image} />
          ))}
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Benefits Offered by Software Maintenance 
          and Support Services"
          paragraph="You can achieve a spectrum of benefits by outsourcing software maintenance and support to FWS. The immediate and long-term merits of considering us as your trusted partner for application software maintenance are as follows -"
        />
        <div className="col-12 p-sm-5">
          <div className="row mainteance_list_wrapper maintiugui">
            <div className="col-12">
              <div className="mainteance_list">
                <div className="imgCont">
                  <img
                    src={images.misc.performanceImprovement}
                    className="dotted"
                    alt="blip"
                  />
                </div>

                <div className="content mx-5">
                  <h1 className="fs-1 ">1. Performance Improvement</h1>
                  <h3 className="mt-2">
                    <ul>
                      <li>
                        Application software maintenance programs usually
                        include upgrades and allow users to enjoy the upgrades
                        for free for a whole year.
                      </li>
                      <li>
                        Upgrades enhance the overall functionality and
                        performance of the software and increase its lifecycle
                        as well.
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mainteance_list">
                <div className="imgCont">
                  <img
                    src={images.misc.bugFixing}
                    className="dotted"
                    alt="blip"
                  />
                </div>

                <div className="content mx-5">
                  <h1 className="fs-1 ">2. Bug Fixing</h1>
                  <h3 className="mt-2">
                    <ul>
                      <li>
                        Application software maintenance packages help protect
                        the software from software problems but are usually
                        meant for a specific time period.
                      </li>
                      <li>
                        After the expiry of the warranty period, you need to pay
                        for the bug fixes yourself.
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mainteance_list">
                <div className="imgCont">
                  <img
                    src={images.misc.currentTrends}
                    className="dotted"
                    alt="blip"
                  />
                </div>

                <div className="content mx-5">
                  <h1 className="fs-1 ">3. Stay Updated with Current Trends</h1>
                  <h3 className="mt-2">
                    <ul>
                      <li>
                        Technology and tech-dependent businesses go through
                        tremendous change.
                      </li>
                      <li>
                        Application software maintenance services can help you
                        keep pace with the current tech trends and ensure that
                        your organization can leverage its benefits
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mainteance_list">
                <div className="imgCont">
                  <img
                    src={images.misc.cutCosts}
                    className="dotted"
                    alt="blip"
                  />
                </div>

                <div className="content mx-5">
                  <h1 className="fs-1 ">4. Cut Costs</h1>
                  <h3 className="mt-2">
                    <ul>
                      <li>
                        With the maintenance of software services, you can keep
                        a tab on your software expenditure.
                      </li>
                      <li>
                        Most programs cover users for a year's period, thereby
                        reducing an organization's investment for IT greatly.
                        When you use cloud-based apps, the monthly charges you
                        pay for those services usually include the maintenance
                        fees as well.
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Mainstream Services" paragraph="" />
        <div className="col-12 p-sm-5">
          <div className="row mainstream_list_wrapper">
            <div className="col-12 col-md-6">
              <div className="mainstream_list">
                <img src={images.misc.star} className="dotted" alt="blip" />

                <div className="content mx-5">
                  <h1 className="fs-2 fw-light">Adaptive Maintenance and Support</h1>
                  <h3 className="mt-2 fs-4 fw-light">
                    We can help you make the necessary modifications and
                    revisions to your software for the transformational needs of
                    your business. Here is an overview of what we do as part of
                    application software maintenance - Data format change,
                    Localization and regulation change, Hardware configuration
                    change, Support Utility Modification, and Operating system
                    integration.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mainstream_list">
                <img src={images.misc.star} className="dotted" alt="blip" />

                <div className="content mx-5">
                  <h1 className="fs-2 fw-light">Corrective Maintenance and Support</h1>
                  <h3 className="mt-2 fs-4 fw-light">
                    We help you fix errors in your software system, whether they
                    are logical errors, coding errors, or design errors.
                    Moreover, we investigate any bug that may arise in your
                    software algorithms.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mainstream_list">
                <img src={images.misc.star} className="dotted" alt="blip" />

                <div className="content mx-5">
                  <h1 className="fs-2 fw-light">Perfective Maintenance and Support</h1>
                  <h3 className="mt-2 fs-4 fw-light">
                    Constant upkeep and special attention are needed to make the
                    most of the technology for your business. We examine your
                    software thoroughly for modifications, rectifications,
                    editing, deletions, additions, and enhancements that it may
                    need, and take necessary action.
                  </h3>
                </div>
              </div>
            </div>{" "}
            <div className="col-12 col-md-6">
              <div className="mainstream_list">
                <img src={images.misc.star} className="dotted" alt="blip" />

                <div className="content mx-5">
                  <h1 className="fs-2 fw-light">Preventive Maintenance</h1>
                  <h3 className="mt-2 fs-4 fw-light">
                    Proactive and Preventive - that is how we define the
                    efficiency of our application software maintenance services.
                    Based on customer feedback and past incidents, we prepare
                    your software for future requirements of your business.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
