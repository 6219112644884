import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import DETAIL_CARD from "../../components/detail_card";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function Mobile() {
  return (
    <div className="container-fluid">
            <Helmet>       
        <title>Mobile App Development</title>
            <meta name="description" content="Design is a fundamental part of our everyday lives. It shapes the way we live, work, and communicate. Design moves beyond form to explore how people interact with products, services, environments and ideas." />
            <meta name='Keywords' content='mobile development, mobile app development, fullstack blockchain development, best product design agencies in toronto'/>
        </Helmet>
      <Banner image={images.banner.mobile}>
        <h1 className="fs-1 yellow-text">Mobile Application</h1>
        <h3>
        We offer our clients both cross-platform and native apps, taking into account all product requirements. We've been utilizing cutting-edge technologies to improve product security, key operations, and provide an exceptional user experience.
        </h3>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Perfection and Quality "
          paragraph="Scenarios which fits best to your product."
        />

        <div className="col-12 p-5">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-5">
              <DETAIL_CARD
                minHeight={"36rem"}
                image={images.service.mobile[0]}
                title={"Fast delivery"}
                text={
                  'We Know How To Create Your App In The Most Efficient Way Possible, From The User-Facing Front-End To The Server Side Behind It. It Is Simple To Get Your App Completely Up And Running With A Set Of Tools That We Use.'
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-5">
              <DETAIL_CARD
                minHeight={"36rem"}
                image={images.service.mobile[1]}
                title={"Excellent Performance"}
                text={
                  "We pay attention to every principle platform difference such as fronts, scrolling, navigation to provide full native performance on IOS and Android."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-5">
              <DETAIL_CARD
                minHeight={"36rem"}
                image={images.service.mobile[2]}
                title={"Modifying UI"}
                text={
                  "We focus on native end-user experience. Our ready-made modules and layered architecture allows to make any changes extremely fast which leads to remarkable and expressive designs."
                }
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="It’s easy go" paragraph="" />

        <div className="col-12 px-5">
          <div className="row">
            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[1]}
              heading={"Discover & Estimate"}
              paragraph={
                "We collect and analyze your requirements to solve the challenge and bring the best solution considering timeline and budget.  As soon as we get your main and agreed app features and design, we are ready to start the journey."
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[0]}
              heading={"Design & Clickable prototype"}
              paragraph={
                "Based on expertise that we’ve got after the first stage, we proceed to clickable prototype creation. We will make sure that you get couple layouts to choose the best way to perform them into UI/UX design."
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[2]}
              heading={"App development"}
              paragraph={
                "Design creation closely relates with development process. We divide development into sprints which gives us a chance to demonstrate you results of our work and get your feedback at every touchpoint."
              }
            />

            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[4]}
              heading={"Testing"}
              paragraph={
                "Quality assurance is necessary for flawless app releases. We put on duty the team of experts that fix any possible bugs and defects and ensure your software goes like a clockwork."
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[3]}
              heading={"Deployment"}
              paragraph={
                "After all work is done, we are ready to show your product to the world and deliver the most enjoyable user experience for your customers."
              }
            />
            <FIRMCARD
              minHeight={"30rem"}
              image={images.misc.mobile[5]}
              heading={"Maintenance"}
              paragraph={
                "After the product release, we support and improve your app to meet demanding end users needs in future. Our team stay in touch with you and ready to help with any issue at any time."
              }
            />
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.android} />
            <TECH_CARD image={images.tech.flutter} />
            <TECH_CARD image={images.tech.swift} />
            <TECH_CARD image={images.tech.native} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
