import { AnimationOnScroll } from "react-animation-on-scroll";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function CUSTOMSOFTWARE() {
  return (
    <div className="container-fluid">
      <div className="banner px-sm-5">
        <div className="row px-sm-5">
          <div className="col-12 align-items-center px-5 banner_content">
            <h1 className="fs-1 mb-5 d-block yellow-text">Custom Software</h1>
            <span className="fs-2">
              Create <span className="yellow-text">custom software</span> based
              on the requirements of your customers. On time delivery of
              high-value software. With the following features, you can create
              custom software alongside product delivery teams.
            </span>
            <h3 className="my-4">
              Create custom software alongside product delivery teams:
            </h3>
            <div className="yellow_list banner_size">
              <ul>
                <li className="fs-3 fw-bold">Web</li>
                <li className="fs-3 fw-bold">Cloud</li>
                <li className="fs-3 fw-bold">DevOps</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Title heading="Web Application Development" paragraph="" />
      <div className="col-12 row px-4">
        <p className="fs-4">
          Whether your consumers are across the office or the globe, you may
          provide stunning web solutions. By relying on our experience
          delivering web applications in a number of industries, you can stay
          focused on the demands of your customers. Deliver for your customers,
          whether they want a beautiful UI, a simple API, or guaranteed
          performance.
        </p>
        <div className="yellow_list col-md-6 col-sm-12">
          <ul>
            <li>Responsive web applications</li>
            <li>Data visualization and digital twins</li>
            <li>Microservices architecture design</li>
            <li>Third-party API integration</li>
            <li>Payments APIs integration</li>
            <li>Multi-device session management</li>
            <li>REST, GraphQL, Websockets and gRPC based API design</li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-12">
          <img
            src={images.service.custom[0]}
            className={"img-fluid"}
            alt="custom software"
          />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Product"
          paragraph="To design amazing products, identify your product market fit by combining your business ambitions with your 
        customers' demands. Define the software and process enhancements that will help your company succeed. 
        We gather feedback from your customers and stakeholders through regular demonstrations of progress and rapidly 
        iterate our way to better solutions. "
        />

        <div className="col-12 row px-4">
          <div className="yellow_list col-12">
            <ul>
              <li>Align your business goals to success metrics </li>
              <li>Prioritize your highest value work</li>
              <li>Build product roadmaps and a backlog of user stories</li>
              <li>Adopt Lean Product practices</li>
              <li>Conduct Design Sprints</li>
              <li>Promote quick build-release-feedback cycles</li>
              <li>
                Focus on user experience in interface and interaction design
              </li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="DevOps"
          paragraph="Build software that strikes a balance between delivery speed and application stability. Increase the frequency of deployments and reduce the time it takes to make modifications. Reduce the number of deployments where changes fail and the time it takes to recover. It will be simple to alter your programme in response to feedback if it is given using these features. "
        />

        <div className="col-12 row px-4">
          <div className="yellow_list col-12">
            <ul>
              <li>Automate deployments using CI/CD </li>
              <li>Optimise builds for better security</li>
              <li>Test-driven design and development</li>
              <li>
                Instrumentation for logging, observability, and monitoring
              </li>
            </ul>
          </div>
        </div>
      </AnimationOnScroll>

      <br />
      <br />
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Cloud"
          paragraph="Make use of the cloud to get a competitive advantage and move more quickly. Spend your time addressing business problems while shortening your time to market and lowering your operating costs. By boosting availability and dependability, you can reduce undifferentiated heavy lifting and provide a consistent experience for all of you rusers."
        />

        <div className="col-12 row px-4">
          <div className="yellow_list col-md-6 col-sm-12">
            <ul>
              <li>Optimise your apps for the cloud </li>
              <li>Modernise your applications using containerization</li>
              <li>Leverage serverless compute opportunistically</li>
              <li>Ensure the data tenancy standards</li>
              <li>Manage workloads on AWS, Azure or Kubernetes</li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12 gif-image">
            <img
              src={images.service.custom[1]}
              className={"img-fluid"}
              alt="custom software"
              height={350}
              width={350}
            />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
