import React, { useEffect, useState } from "react";
import images from "../../helpers/uploads";
import { FaLinkedin, FaFacebook, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import details from "./details";

export default function Footer() {
  const [footerData, setFooterData] = useState({});
 
  const getIpAddress = async () => {
    const countryCode = await (
      await axios.get("https://geolocation-db.com/json/")
    ).data.country_code;
    setFooterData(details[countryCode] ? details[countryCode] : details["CA"]);
  };
  useEffect(() => {
    getIpAddress();
  }, []);
  return (
    <>
      <footer className="footer-section mt-5">
        <div className="container mt-5">
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-5">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="index.html">
                      <img
                        src={images.logo}
                        className="img-fluid"
                        alt="logo"
                      ></img>
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p className="fs-4">
                      {footerData?.name} <br></br>
                      {footerData?.address?.map((address, index) => (
                              <span>
                              {address}<br></br>
                              </span>
                            ))}
                     
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <h3>
                          <p className="fs-4">contact us:</p>
                        </h3>
                        <div className="col-12" style={{ display: "flex" }}>
                          <img
                            src={images.misc[footerData?.countryCode]}
                            width="15"
                            height="15"
                          ></img>
                          &nbsp;&nbsp;&nbsp;
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {footerData?.phone?.map((phone, index) => (
                              <span
                                style={{ color: "#858B8F" }}
                                className="fs-6"
                              >
                        
                                {" "}
                                {phone}{" "}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <img src={images.misc.envolap} width="15"></img>
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ color: "#858B8F" }} className="fs-6">
                          {footerData?.email?.map((email, index) => email)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2 mb-3">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Company</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/jobpart">Jobs</Link>
                    </li>
                    <li>
                      <Link
                        to="/about#culture"
                        onClick={() => {
                          document
                            .getElementById("culture")
                            .scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        Culture
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2 mb-3">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Solutions</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/custom">Build Custom Software</Link>
                    </li>
                    <li>
                      <Link to="/tecnical">Technical Assessments</Link>
                    </li>
                    <li>
                      <Link to="/interm">Interim CTO</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2 mb-3">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Customers</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/contact">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/consulting">Get a Live Consultation</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Sales</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2 mb-3">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Resources</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Medium Articles</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-lg-left">
                <div className="copyright-text">
                  <p className="fs-4">
                    © {new Date().getFullYear()} Devstack Soft Solutions Inc.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href="https://www.linkedin.com/company/78982816/"> <FaLinkedin className="FaLinkedin" /> </a> 
                    </li>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100077706907386">
                        <FaFacebook className="FaFacebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/DevstackSoft/">
                        <FaTwitterSquare className="FaTwitterSquare" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
