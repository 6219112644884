import { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { FaPlus, FaMinus } from "react-icons/fa";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";

export default function FULLSTACK() {
  useEffect(() => {
    const accordionBtns = document.querySelectorAll(".accordion");

    accordionBtns.forEach((accordion) => {
      accordion.onclick = function () {
        this.classList.toggle("accordion-is-open");

        let content = this.nextElementSibling;

        if (content.style.maxHeight) {
          //this is if the accordion is open
          content.style.maxHeight = null;
        } else {
          //if the accordion is currently closed
          content.style.maxHeight = content.scrollHeight + "px";
        }
      };
    });
  });
  return (
    <div className="container-fluid faq-page">
      <Banner image={images.banner.faq}>
        <span>
          <h1 className="fs-1 yellow-text mb-4">Frequently Asked Questions</h1>
          <h2>Common questions when considering Devstack Workspace.</h2>
        </span>
      </Banner>
      <div className="faq-accordation">
        {[
          {
            title:
              "Can your developers work with my existing systems / tools/ CRM, etc?",
            text: "Of course! our standard approach has always been to keep our end of services as open-ended as possible for our clients. We have had several clients who already owned an app or website and wanted us to upgrade our work with their existing tools, systems, and CRM.",
          },
          {
            title: "Do you sign NDA?",
            text: "Yes, you don’t have to worry as soon as you contact us. We follow a very strict NDA guideline so that your idea remains safe. Both parties will sign NDA, this will protect the assets and rights of both parties. To receive our NDA you can send us an email as consultations@devstacksoftsiolutions.com          ",
          },
          {
            title:
              "How much data is required to develop an Machine Learning based solution",
            text: "The entire successful machine learning model is data driven. Deep learning operations require about 1,500 data point units, including images, documents, and overview, for any machine learning based testing / validation. ",
          },
          {
            title:
              "I am not a computer geek, will I be able to work with your team?",
            text: "Definitely, you can. We would be happy to fulfill all the technology gap between us.",
          },
          {
            title:
              "Can I know your developers’ core strength and industry experience?",
            text: "We have a pool of talented full-stack developers who have years of experience working on top-notch and emerging technologies.",
          },
          {
            title:
              "Does your company work according to my time-zone preference?",
            text: "Yes, we work according to your timezone (PST/EST/CST/MST). We put constant efforts towards completing the project within the deadline.",
          },
          {
            title: "How complete is my ownership?",
          },
        ].map((el) => (
          <>
            <button className="accordion border-bottom"><span className="ms-4">{el.title}</span></button>
            <div className="accordion-content">
              <p className="fs-4 py-4">{el.text}</p>
            </div>
          </>
        ))}
      </div>
      <div className="map-sec">
        <h1 className="fs-1 yellow-text mx-5">Our Offices:</h1>
        <img className="map-image " src={images.misc.map}></img>
        <ul className="row contact-box">
          <li className="col-md-6">
            <img src={images.misc.location} width="30"></img>&nbsp;
            <p className="fs-4">
              Devstack Soft Solutions Inc 2967 Dundas St. W., #435d Toronto, ON,
              Canada, M6P 1Z2
            </p>
          </li>
          <li className="col-md-6">
            <img src={images.misc.location} width="30"></img>&nbsp;
            <p className="fs-4">
              Devstack Soft Solutions Private Limited 30, Indrayani Colony,
              Amravati, Maharashtra-444607
            </p>
          </li>
          <li className="col-md-6">
            <img src={images.misc.canidaFlag} width="30"></img>&nbsp;
            <p className="fs-4">+1-647-695-7080</p>
          </li>
          <li className="col-md-6">
            <img src={images.misc.indiaFlag} width="30"></img>&nbsp;
            <p className="fs-4">
              +91-9404863009<br></br> +91-7720002402
            </p>
          </li>
          <li className="col-md-6">
            <img src={images.misc.envolapBlue} width="30"></img>&nbsp;
            <p className="fs-4">info@devstacksoftsolutions.com</p>
          </li>
          <li className="col-md-6">
            <img src={images.misc.envolapBlue} width="30"></img>&nbsp;
            <p className="fs-4">info@devstacksoftsolutions.com</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
