import { Link } from "react-router-dom";

export default function Button(props) {
  if (props.text === "Get a Free Consultation") {
    return (
      <>
        <Link to="/contact">
          <button className={"yellow_btn_lg"}>{props.text}</button>
        </Link>
      </>
    );
  }
  return (
    <>
      <button className={"yellow_btn_lg"}>{props.text}</button>
    </>
  );
}
