import Banner from "../../components/banner";
import Button from "../../components/button";
import SERVICE_CARD from "../../components/service_card";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Helmet from "helmet";

export default function BLOCKCHAIN() {
  return (
    <div className="container-fluid">
        <Helmet>
            <title>Blockchain</title>
            <meta name="description" content="Devstack Soft Solution is a Toronto-based blockchain consultancy, specializing in smart contract development, Ethereum development, and cryptocurrency, provides blockchain security services to companies that want to explore this new technology." />
            <meta name='Keywords' content='blockchain consulting in toronto, blockchain technology services'/>
          
        </Helmet>
      <Banner image={images.banner.home}>
        <span>
          Build a <span className="yellow-text">DApp</span> equipped with the
          efficiency, security and service with our custom blockchain
          development services and Smart Contracts.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Blockchain Lab"
          paragraph="Scenarios which fits best to your product."
        />

        <div className="col-12 p-5">
          <div className="row Blockchain-lab">
            <div className="col-12 col-sm-6 col-md-6 mt-5">
              <SERVICE_CARD
                minHeight="15rem"
                image={images.service.blockchain[0]}
                title={"Public"}
                text={
                  "DApp based solely on public blockchain technologies."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 mt-5">
              <SERVICE_CARD
                minHeight="15rem"
                image={images.service.blockchain[1]}
                title={"Private"}
                text={
                  "DApp Created For Businesses Using Private Blockchain Technologies."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 mt-5">
              <SERVICE_CARD
                minHeight="15rem"
                image={images.service.blockchain[2]}
                title={"Consortium"}
                text={
                  "DApp With Several Stakeholders The Process's Trust."
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 mt-5">
              <SERVICE_CARD
                minHeight="15rem"
                image={images.service.blockchain[3]}
                title={"Hybrid"}
                text={
                  "To ensure privacy and efficiency, a hybrid DApp was created by combining public and private blockchain technologies." 
                }
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="We will help you" paragraph="" />

        <div className="col-12 p-5">
          <div className="row inline_list_wrapper">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="inline_list">
                <div className="dotted"></div>
                <div className="content mx-4">
                  <h3>Reduce your transaction costs</h3>
                  <p className="mt-2">
                  We will assist you in lowering your transaction costs by thoroughly reviewing your product and implementing solutions to lower public blockchain costs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="inline_list">
                <div className="dotted"></div>
                <div className="content mx-4">
                  <h3>Improve your security and Transparency</h3>
                  <p className="mt-2">
                  We will assist you in improving the transparency of your Smart Contracts or Chaincode by implementing Secure design principles and off chaining strategies for data privacy.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="inline_list">
                <div className="dotted"></div>
                <div className="content mx-4">
                  <h3>Boost your transaction speed</h3>
                  <p className="mt-2">
                  We will assist you in achieving transaction efficiency by thoroughly reviewing your network architecture and process flow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5 tech_wrapper">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.ehhreum} />
            <TECH_CARD image={images.tech.hyperledger} />
            <TECH_CARD image={images.tech.rippple} />
            <TECH_CARD image={images.tech.eos} />
            <TECH_CARD image={images.tech.iota} />
            <TECH_CARD image={images.tech.polygon} />
            <TECH_CARD image={images.tech.r3} />
            <TECH_CARD image={images.tech.steller} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
