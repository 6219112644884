export default {
    "CA": {
      "name": "Devstack Soft Solutions Inc.",
      "address": ["39 Roehampton Ave", "Toronto ON M4P0G1"],
      "email": ["info@devstacksoftsolutions.com"],
      "phone": ["+1 (647) 375-1285"],
      "countryCode": "CA"
    }, 
    "IND": {
      "name": "Devstack Soft Solutions Pvt. Ltd",
      "address": "30, Indrayani Colony, Amravati, Maharashtra-444607",
      "email": ["info@devstacksoftsolutions.com"],
      "phone": ["+91-9404863009", "+91-7720002402"],
      "countryCode": "IND"
    },
  }