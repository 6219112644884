import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function QUANTUM() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>Quantum Computing</title>
            <meta name="description" content=" Quantum Computing Technology, Devstack Soft Solution is a privately owned company founded in 2022. Devstack Soft Solution provides both basic and advanced computer services for the small business in Toronto, ON" />
            <meta name='Keywords' content='quantum computing technology'/>
        </Helmet>
      <Banner image={images.banner.quantum}>
        <span>
          Build a next generation{" "}
          <span className="yellow-text">Quantum Computing</span> Apps with our
          Expertise.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Quantum Computing Lab" paragraph="" />

        <div className="col-12">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 col-sm-6">
              <img
                className="img-fluid"
                src={images.service.quantum[0]}
                alt="IOT"
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Build your next generation Quantum Computing App"
          paragraph=""
        />
        <div className="col-12 p-5">
          <div className="row">
            <FIRMCARD
              image={images.misc.qntm[1]}
              heading={"Hardware"}
              paragraph="Analyze The Hardware For The Use Casethe hardware for the use case."
            />
            <FIRMCARD
              image={images.misc.qntm[0]}
              heading={"Algorithms"}
              paragraph="Design Algorithms Optimized To The Circuit And Pulse Level."
            />
            <FIRMCARD
              image={images.misc.qntm[2]}
              heading={"Machine Learning"}
              paragraph="Develop Quantum Enabled Machine Learning Solutions."
            />
            <FIRMCARD
              image={images.misc.qntm[3]}
              heading={"Security"}
              paragraph="Implement Quantum Safe Cryptographic Approaches."
            />
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.qiskit} />
            <TECH_CARD image={images.tech.openql} />
            <TECH_CARD image={images.tech.brackets} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
