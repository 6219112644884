export default function TECH_CARD(props) {
  return (
    <>
      <div className="col-md-3 col-sm-6 col-md-4 mt-5">
        <div className="card tech_card">
          <div className="card-body">
            <img src={props.image} alt="tech" />
          </div>
        </div>
      </div>
    </>
  );
}
