import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function IOT() {
  return (
    <div className="container-fluid">
      <Helmet>
            <title>Iot</title>
            <meta name="description" content="The best product design agencies in Toronto know that every business needs a strong brand identity, and they know how to create one. A team of designers and engineers that are passionate about creating something new. We are not afraid to push boundaries, break rules, and innovate." />
            <meta name='Keywords' content='best product design agencies in toronto'/>
        </Helmet>
      <Banner image={images.banner.iot}>
        <span>
          Build a Secure and Efficient <span className="yellow-text">IOT</span>{" "}
          Workflow with our IOT Expert Engineering Team.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="IOT LAB"
          paragraph="Design a secure IoT worflow for your product."
        />

        <div className="col-12 p-5">
          <div className="row d-flex justify-content-center">
            <img
              className=""
              style={{ width: "80%" }}
              src={images.service.iot[0]}
              alt="IOT"
            />
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Smart Solutions for your digital Transformation"
          paragraph=""
        />
        <div className="col-12 p-5">
          <div className="row">
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.iot[0]}
              heading={"Firmware Design"}
              paragraph="Create Your Own Embedded System Using Your PLC Or Microcontrollers."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.iot[1]}
              heading={"Data Warehouse"}
              paragraph="Consolidate diverse data sources to create a scalable, secure, High-Performance, And Cost-Effective Data Store."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.iot[2]}
              heading={"R&D and Prototyping"}
              paragraph="Determine the requirements for the best hardware to build a prototype."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.iot[3]}
              heading={"Platform Architecture"}
              paragraph="Create a responsive, scalable, and secure platform that includes monitoring capabilities."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.iot[4]}
              heading={"Data Analytics and Visualization"}
              paragraph="Modern Workflow Benefits From Advanced Predictive And Prescriptive Analytics."
            />
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.rasberryPi} />
            <TECH_CARD image={images.tech.ardino} />
            <TECH_CARD image={images.tech.matlab} />
            <TECH_CARD image={images.tech.zigbee} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
