import Banner from "../../components/banner";
import Button from "../../components/button";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ROUND_CARD_2 from "../../components/round_card_2";

export default function jodPart() {
  return (
    <div className="container-fluid job-part-sec">
      <Banner image={images.banner.readyToWork}>
        <h1 className="fs-1 yellow-text fw-light mb-4">Jobs</h1>
        <span className="fs-3 fw-light">
          Discover The Talent Transformation journey at Devstack.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Open positions" paragraph="" />
        <h3 className="px-5 fs-4 fw-light">
          Let’s write some beautiful code and make smart designs. This is what
          we’re crazy about, and we’re looking for the ones who feel the same.
        </h3>
        <br />
        <br />

        <div className="col-12 px-5 open-position my-4">
          <div className="row d-flex align-items-center ">
            <div className="col-12 col-sm-6 row">
              <div className="col-12">
                <h1 className="fs-1 ">DevOps Engineer</h1>
              </div>
              <div className="col-12 row d-flex align-items-center my-3 justify-content-center">
                <div className="col-2  d-flex align-items-center justify-content-center">
                  <img
                    src={images.misc.indiaFlag}
                    style={{ width: "32px", height: "32px" }}
                  ></img>
                </div>
                <div className="col-10 d-flex align-items-center">
                  <h3 className="fs-4 fw-bold">Nagpur, Maharashtra</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 row d-flex justify-content-sm-end">
              <button className="open-position_button">Apply Now</button>
            </div>
          </div>
        </div>
        <div className="col-12 px-5 open-position my-4">
          <div className="row d-flex align-items-center ">
            <div className="col-12 col-sm-6 row">
              <div className="col-12">
                <h1 className="fs-1 ">Junior UX/UI Designer</h1>
              </div>
              <div className="col-12 row d-flex align-items-center my-3 justify-content-center">
                <div className="col-2  d-flex align-items-center justify-content-center">
                  <img
                    src={images.misc.indiaFlag}
                    style={{ width: "32px", height: "32px" }}
                  ></img>
                </div>
                <div className="col-10 d-flex align-items-center">
                  <h3 className="fs-4 fw-bold">Nagpur, Maharashtra</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 row d-flex justify-content-sm-end">
              <button className="open-position_button">Apply Now</button>
            </div>
          </div>
        </div>
        <div className="col-12 px-5 open-position my-4">
          <div className="row d-flex align-items-center ">
            <div className="col-12 col-sm-6 row">
              <div className="col-12">
                <h1 className="fs-1 ">Junior React.JS</h1>
              </div>
              <div className="col-12 row d-flex align-items-center my-3 justify-content-center">
                <div className="col-2  d-flex align-items-center justify-content-center">
                  <img
                    src={images.misc.indiaFlag}
                    style={{ width: "32px", height: "32px" }}
                  ></img>
                </div>
                <div className="col-10 d-flex align-items-center">
                  <h3 className="fs-4 fw-bold">Nagpur, Maharashtra</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 row d-flex justify-content-sm-end">
              <button className="open-position_button">Apply Now</button>
            </div>
          </div>
        </div>

        <div className="open-position_footer">
          <h2 className="open-position_footer_heading fs-2 fw-light">
            Wanna join the team?
          </h2>
          <button className="open-position_button">Apply Now</button>
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Corporate values" paragraph="" />
        <div className="row corporate-values-sec p-5">
          {[
            {
              title: "Transparency",
              image: images.misc.transparency,
            },
            {
              title: "Innovativeness",
              image: images.misc.rocket,
            },
            {
              title: "Client-thinking",
              image: images.misc.busybrain,
            },
            {
              title: "Result orientedness",
              image: images.misc.results,
            },
            {
              title: "Reliability",
              image: images.misc.reliability,
            },
            {
              title: "Effectiveness",
              image: images.misc.projectmanagement,
            },
          ].map((el, index) => (
            <div className="col-12 col-lg-4 col-md-6 my-4 d-flex justify-content-center">
              <ROUND_CARD_2 title={el.title} image={el.image} />
            </div>
          ))}
        </div>
      </AnimationOnScroll>
      <br />
      <br />
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="We offer" paragraph="" />
        <div className="row  p-5">
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Challenging projects</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    We use innovative technologies such as blockchain and AI.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Great team</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    The closely-knit team of experts who are passionate about
                    what they do.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Flexible working hours</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    Choose comfortable working hours according to your
                    biorhythms and personal needs.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Career growth</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    Ongoing professional development and personal growth is our
                    standard.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Comfortable office</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    Modern cozy office facilities with friendly atmosphere and
                    needed environment.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 my-3">
            <div className="col-12 row ">
              <div className="col-12 col-sm-2 ">
                <img
                  src={images.misc.star}
                  style={{ width: "3rem", height: "3rem" }}
                ></img>
              </div>
              <div className="col-10">
                <h2 className="fs-2 fw-light">Challenging projects</h2>
                <div className="col-12">
                  <h3 className="fs-4 fw-light mt-2">
                    We use innovative technologies such as blockchain and AI.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
