export default function Banner(props) {
  return (
    <>
      <div className="banner">
        <div className="row">
          <div className="col-md-6 col-sm-12 d-flex align-items-center px-5 banner_content">
            {/* <h1 className="fs-1 text">{props.children}</h1> */}
            <span className="display-6 fs-3">{props.children}</span>
          </div>
          <div className="col-md-6 col-sm-12 banner_image d-flex justify-content-md-end justify-content-center">
            <img src={props.image} className="img-fluid" alt="banner" />
          </div>
        </div>
      </div>
    </>
  );
}
