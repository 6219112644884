import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import FIRMCARD from "../../components/firmCard";
import TECH_CARD from "../../components/tech_cards/index";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function VR() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>Virtual Reality</title>
            <meta name="description" content="VR and AR are the technologies of the 4th Industrial Revolution. We help enterprises, organizations, institutions, and governments find their best business case for using virtual reality and augmented reality technology to improve their operations, products, services or customer interaction." />
            <meta name='Keywords' content='virtual reality and augmented reality technology'/>
        </Helmet>
      <Banner image={images.banner.vr}>
        <span>
          Build a next generation <span className="yellow-text">Virtual Reality/Argument </span> Lab Apps with our
          Expertise.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Virtual Reality Lab"
          paragraph="Design a virtual world with the creative imagination."
        />

        <div className="col-12">
          <div className="row justify-content-center align-items-center">
            <div className="col-6">
              <img
                className="img-fluid"
                src={images.service.vr[0]}
                alt="IOT"
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Empower your product with Our 
        Vritual Reality Services"
          paragraph=""
        />
        <div className="col-12 p-5">
          <div className="row">
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.vr[0]}
              heading={"3D Modelling"}
              paragraph="Make pixel-rich 3D models for AR/VR applications."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.vr[1]}
              heading={"AR/VR Training"}
              paragraph="To maximize learner outcomes, seamlessly layer training simulations on top of real-world scenarios."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.vr[2]}
              heading={"Gamification"}
              paragraph="Make Memorable Games That Incorporate A New Level Of Play Into Everyday Life."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.vr[4]}
              heading={"AR/VR Data Visulization"}
              paragraph="With Live Interactivity, Overlay Complex Data Into The Real World."
            />
            <FIRMCARD
              minHeight={"20rem"}
              image={images.misc.vr[3]}
              heading={"Cinematic VR"}
              paragraph="With Incredible VR Film Experiences, you can create an exciting story."
            />
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.unity} />
            <TECH_CARD image={images.tech.meta} />
            <TECH_CARD image={images.tech.keras} />
            <TECH_CARD image={images.tech.unreal} />
            <TECH_CARD image={images.tech.maya} />
            <TECH_CARD image={images.tech.blender} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
