export default function DETAIL_CARD_1(props) {
  return (
    <>
      {/* <div className="col-12 col-md-6 "> */}
      <div
        className="card service_card_2 "
        style={{ minHeight: `${props.minHeight}` }}
      >
        <div className="card-body  d-flex align-items-center justify-content-center">
          <div className="row ">
            <img
              src={props.image}
              className="service_card_2_image"
              alt="service"
            />

            <div className="col-12 d-flex flex-column justify-content-center align-items-center text-center card_content">
              <p
                className="service_card_2_desc mt-3"
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  lineHeight: "2.5rem",
                }}
              >
                {props.text}.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div/> */}
    </>
  );
}
