import { AnimationOnScroll } from "react-animation-on-scroll";
import Banner from "../../components/banner";
import Button from "../../components/button";
import SIDECARD from "../../components/side_card";
import TECH_CARD from "../../components/tech_cards";
import Title from "../../components/title";
import images from "../../helpers/uploads";
import Helmet from "helmet";

export default function SystemENG() {
  return (
    <div className="container-fluid">
      <Helmet>       
        <title>System Engineering</title>
            <meta name="description" content="We are a software company with office in Toronto. Our clients range from small local businesses to multinational corporations. We develop custom solutions that meet each client's unique needs" />
            <meta name='Keywords' content='software companies in canada toronto'/>
        </Helmet>
      <Banner image={images.banner.eng}>
        <span>
          <h1 className="fs-1 yellow-text mb-4">System Engineering</h1>
          <span className="yellow-text">Systems Engineering</span>  
          provides integrated technology solutions that operate invisibly, seamlessly, and securely.
        </span>
      </Banner>
      <div className="col-12 d-flex justify-content-center">
        <Button text="Get a Free Consultation" />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title
          heading="Linux Software Development Services 
          we offer"
          paragraph="We can customize and integrate any of our Linux services to meet the specific needs of your business. We provide our clients with a wide range of linux and unix software development solutions, including:"
        />

        <div className="col-12 p-5">
          <div className="row protection_wrapper">
            <div className="col-12 col-md-6 col-lg-6">
              <SIDECARD
                minHeight={"40rem"}
                image={images.misc.eng[3]}
                title={"Linux Web Development "}
                text={`We have expertise in LAMP, also known as Linux, Apache, MySQL, and PHP development stack. These are highly useful for managing heavily coded websites and complex e-commerce solutions. Our dedicated LAMP developers offer in-depth expertise to offer high-quality Linux development services.`}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <SIDECARD
                minHeight={"40rem"}
                image={images.misc.eng[1]}
                title={"Linux Application Development for Desktop"}
                text={`To meet our clients' application needs, we can assist in the creation of customizable and scalable applications using C/C++ and Delphi/Lazarus, as well as QT or wxWidgets for the GUI toolkit.`}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <SIDECARD
                minHeight={"40rem"}
                image={images.misc.eng[2]}
                title={"Linux Embedded Development"}
                text={`Linux is increasingly being used on embedded systems for device connectivity and multimedia use. Our development team can provide one-of-a-kind application solutions and create Linux software for embedded devices using advanced machine languages such as Python, Object Pascal, C#, and others.`}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <SIDECARD
                minHeight={"40rem"}
                image={images.misc.eng[0]}
                title={"Linux/Unix GUI Programming and Development"}
                text={`We can create programmes with graphical user interfaces (GUIs) in a variety of ways. On Unix/Linux, the most popular options are Zenity, which uses the Gnome/GTK+ toolkit, and Kdialog, which uses the QT toolkit. We offer a variety of other dialogue programmes to clients based on their preferences.`}
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Our Linux Software Development Process" paragraph="" />
        <div className="col-12 p-5">
          <div className="row system_list_wrapper">
            <div className="col-12">
              <div className="system_list">
                <div className="dotted">
                  <img
                    src={images.misc.engCard[1]}
                    className="img-fluid"
                    alt="blip"
                  />
                </div>
                <div className="content mx-5">
                  <h1 className="fs-1 ">
                    Dividing the project into categories{" "}
                  </h1>
                  <h3 className="mt-2">
                  Our team begins by analyzing the goal of Linux software development as well as our client's expectations from the project.

                  </h3>
                </div>
              </div>
              <div className="system_list">
                <div className="dotted">
                  <img
                    src={images.misc.engCard[0]}
                    className="img-fluid"
                    alt="blip"
                  />
                </div>
                <div className="content mx-5">
                  <h1 className="fs-1 ">Reviewing client needs</h1>
                  <h3 className="mt-2">
                  Our team begins by examining the goal of Linux software development as well as our client's expectations from the project.

                  </h3>
                </div>
              </div>
              <div className="system_list">
                <div className="dotted">
                  <img
                    src={images.misc.engCard[3]}
                    className="img-fluid"
                    alt="blip"
                  />
                </div>
                <div className="content mx-5">
                  <h1 className="fs-1 ">Testing and rigorous QC </h1>
                  <h3 className="mt-2">
                  Intensive testing and multiple level quality checks are performed during and after the initial development of the application to identify and eliminate any discrepancies.

                  </h3>
                </div>
              </div>
              <div className="system_list">
                <div className="dotted">
                  <img
                    src={images.misc.engCard[2]}
                    className="img-fluid"
                    alt="blip"
                  />
                </div>
                <div className="content mx-5">
                  <h1 className="fs-1 ">Programming and development</h1>
                  <h3 className="mt-2">
                  The respective teams begin working on coding, programming, software development, interface design, and so on. 
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" initiallyVisible={false}>
        <Title heading="Technologies" paragraph="" />
        <div className="col-12 px-5 tech_wrapper">
          <div className="row tech_list">
            <TECH_CARD image={images.tech.clion} />
            <TECH_CARD image={images.tech.eclipse} />
            <TECH_CARD image={images.tech.valgrind} />
            <TECH_CARD image={images.tech.gimp} />
            <TECH_CARD image={images.tech.handBrake} />
            <TECH_CARD image={images.tech.clang} />
            <TECH_CARD image={images.tech.qt} />
            <TECH_CARD image={images.tech.clonezilla} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
